// Chakra imports
import 
{ 
    Center, 
    CircularProgress, 
    Flex, 
    Text, 
    useColorModeValue,
    Table,
    Tbody,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
// Custom components
import React, { useState } from "react";
import TablesTableRow from "components/Tables/TablesTableRow";
import ATR150 from "assets/img/aiops/ATR150.png";
import peopleimage from "assets/img/aiops/stack.png";



const LabHistory = ({ title, captionItems, data, isLoading }) => {
    const textColor = useColorModeValue("purple.700", "white");


    return (
        <>
            <Table variant='simple' color={textColor}>
                <Thead>
                    {!isLoading ? " " : <CircularProgress isIndeterminate color='purple.300' />}
                    {(() => {
                        if (!isLoading) {
                            if (data.length == 0) {
                            } else {
                                return (
                                    <Tr my='.8rem' pl='0px' color='purple.400'>
                                        {captionItems.map((caption, idx) => {
                                            return (
                                                <Th color='purple.400' key={idx} ps={idx === 0 ? "0px" : null} fontSize="md">
                                                    {/* {caption} */}
                                                    {caption}
                                                </Th>
                                            );
                                        })}
                                    </Tr>
                                )
                            }
                        }
                    }
                    )()}
                </Thead>
                {(() => {
                    if (!isLoading) {
                        if (data.length == 0) {
                            return (<p style={{ marginTop: "-1%" }}>No Lab Sessions</p>)
                        } else {
                            return (
                                <Tbody>
                                    {
                                        data.map((row) => {
                                            return (
                                                <TablesTableRow
                                                    key={row.OrderItemUId}
                                                    domainContextRoot={row.DomainContextRoot}
                                                    logo={peopleimage}
                                                    accessLinkURL={row.AccessLinkURL}
                                                    module={row.Module}
                                                    aaamPlatformType={row.AaamPlatformType}
                                                    status={row.Status}
                                                    date={row.CreateDate}
                                                />
                                            )
                                        })
                                    }
                                </Tbody>
                            )
                        }
                    }
                })()}

            </Table>

        </>
    );
};

export default LabHistory;
