// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import PaymentStatistics from "./PaymentStatistics";
import { BellIcon, ViewIcon } from '@chakra-ui/icons'

import React from "react";
import ATRForm from "./ATRForm";
import Stack360Form from "./Stack360Form";
import SplunkForm from "./SplunkForm";
import PlainInstanceForm from "./PlainInstanceForm";
import QuasarForm from "./QuasarForm";
import GenwizardForm from "./GenwizardForm";


const NewLab = ({ title, captions, data }) => {
  const textColor = useColorModeValue("purple.700", "white");
  return (
    <>
      <ATRForm
        icon={<ViewIcon h={"24px"} w={"24px"} color='white' />}
        title={"ATR"}
        description={"12Hrs | Self-guided | Hands-On"}
        amount={0}
      />
      <GenwizardForm
        icon={<ViewIcon h={"24px"} w={"24px"} color='white' />}
        title={"Genwizard"}
        description={"12Hrs | Self-guided | Hands-On"}
        amount={0}
      />
      {/* <PaymentStatistics
        icon={<ViewIcon h={"24px"} w={"24px"} color='white' />}
        title={"ATR CaaS"}
        description={"10Hrs | Self-guided | Hands-On"}
        amount={0}
      /> */}
      <Stack360Form
        icon={<ViewIcon h={"24px"} w={"24px"} color='white' />}
        title={"Stack Suite"}
        description={"10Hrs | Self-guided | Hands-On"}
        amount={0}
      />
      <SplunkForm
        icon={<ViewIcon h={"24px"} w={"24px"} color='white' />}
        title={"Splunk Training"}
        description={"10Hrs | Self-guided | Hands-On"}
        amount={0}
      />
      <PlainInstanceForm
        icon={<ViewIcon h={"24px"} w={"24px"} color='white' />}
        title={"Plain Instance"}
        description={"OS - CentOS"}
        amount={0}
      />
      <QuasarForm
        icon={<ViewIcon h={"24px"} w={"24px"} color='white' />}
        title={"Quasar"}
        description={"10Hrs | Self-guided | Hands-On"}
        amount={0}
      />
      {/* <PaymentStatistics
        icon={<ViewIcon h={"24px"} w={"24px"} color='white' />}
        title={"Chatbot"}
        description={"10Hrs | Self-guided | Hands-On"}
        amount={0}
      /> */}

    </>
  );
};

export default NewLab;
