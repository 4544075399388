// Chakra imports
import {
    Flex,
    Grid,
    Image,
    SimpleGrid,
    useColorModeValue,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Text, Link, Button
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
// Custom icons
import {
    CartIcon,
    DocumentIcon,
    GlobeIcon,
    WalletIcon,
} from "components/Icons/Icons.js";
import React from "react";
import Axios from "axios";

export default function Faq() {
    // Chakra Color Mode

    const textColor = useColorModeValue("purple.700", "white");
    const rootUrl = window.location.href.split("/faq")[0];
    const baseURL = "https://api-cam-training.mywizard-aiops.com";
    const eid = localStorage.getItem("eid");


    const fetchPresignedUrl = (fileName) => {
        const idToken = localStorage.getItem("token");
        const urls = baseURL + "/faq";
        if (idToken) {
          Axios
            .get(
              urls +
              `/${fileName}?customer_enterprise_id=${eid}&inititive=na`,
              {
                headers: { Authorization: idToken },
              }
            )
            .then((res) => {
              window.open(res.data[0], "_blank");
            })
            .catch((err) => {
    
            });
        }
      };

    return (
        <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
            <Grid
                templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
                templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
                gap='24px'>
                <Card p='16px' >
                    <CardHeader p='12px 0px 28px 0px'>
                        <Accordion defaultIndex={[0]} style={{ width: "100%" }}>

                            <AccordionItem>
                                <h2>
                                    <AccordionButton _expanded={{ bg: '#805AD5', color: 'white' }}>
                                        <Box as="span" flex='1' textAlign='left' style={{ padding: "6px" }}>
                                            Training guides
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} style={{ paddingLeft: "4%", paddingTop: "20px", paddingBottom: "25px" }}>
                                    <p>
                                        <Link
                                            href="https://ts.accenture.com/sites/ATRTraining"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#9f7aea" }}
                                        >
                                            <b>ATR Training Site</b>
                                        </Link>{" "}
                                    </p>
                                    
                                        <Button
                                            color="rgb(159, 122, 234)"
                                            target="_blank" fontSize='16px' fontWeight='bold'
                                            style={{ backgroundColor: "white", padding: "0px" }}
                                            onClick={() =>
                                                fetchPresignedUrl("training-userguide.pdf")
                                            }  
                                            >                          
                                            Training Portal User Guide
                                        </Button>
                                    
                                    {/* <p>
                                    <Link
                                        href="https://ts.accenture.com/sites/myWizardBootCampATCI-Stack/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FmyWizardBootCampATCI%2DStack%2FShared%20Documents%2FGeneral%2FStack%20%2D%20Day%201%20Material%2FStack%5FArchitecture%2Epdf&parent=%2Fsites%2FmyWizardBootCampATCI%2DStack%2FShared%20Documents%2FGeneral%2FStack%20%2D%20Day%201%20Material"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{color:"#9f7aea"}}
                                        >
                                        <b>Stack Suite Training Site</b>
                                    </Link>{" "}
                                </p> */}
                                </AccordionPanel>

                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton _expanded={{ bg: '#805AD5', color: 'white' }}>
                                        <Box as="span" flex='1' textAlign='left' style={{ padding: "6px" }}>
                                            How can I request SSH Access to an Instance?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} style={{ paddingTop: "20px", paddingBottom: "25px" }}>
                                    <p>If you haven't registered your SSH key, please register here: <Link
                                        href={rootUrl + "/ssh"}
                                        rel="noopener noreferrer"
                                        style={{ color: "#9f7aea" }}
                                    >
                                        <b>Access Page</b>
                                    </Link></p><br></br>
                                    <p>If you have registered your SSH Key, please follow the instructions below:</p>
                                    <p>1. Navigate to <Link
                                        href={rootUrl + "/ssh"}
                                        rel="noopener noreferrer"
                                        style={{ color: "#9f7aea" }}
                                    >
                                        <b>Access</b>
                                    </Link>{" "}
                                    </p>
                                    <p>2. In the Request Access section, enter the Private DNS of the server you want to access.</p>
                                    <p>3. Submit it by clicking on “Request Access”</p>
                                    <p>4. When the owner of the instance approves your request, your access will be added.</p>
                                    <p><i>Note: If you are the owner of the instance, your access will be added automatically.</i></p>

                                </AccordionPanel>

                            </AccordionItem>

                            <AccordionItem>
                                <h2>
                                    <AccordionButton _expanded={{ bg: '#805AD5', color: 'white' }}>
                                        <Box as="span" flex='1' textAlign='left' style={{ padding: "6px" }}>
                                            How do I provide SSH Access to my Instance for others?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} style={{ paddingTop: "20px", paddingBottom: "25px" }}>

                                    <p>Method 1:</p>
                                    <p>1. Once the user requests access to your instance, you can view the request data under "Pending Approvals" in <Link
                                        href={rootUrl + "/ssh"}
                                        rel="noopener noreferrer"
                                        style={{ color: "#9f7aea" }}
                                    ><b>Access</b></Link> Page.</p>
                                    <p>2. Click on "Approve" button.</p>
                                    <br></br>
                                    <p>Method 2:</p>
                                    <p>1. Navigate to <Link
                                        href={rootUrl + "/ssh"}
                                        rel="noopener noreferrer"
                                        style={{ color: "#9f7aea" }}
                                    >
                                        <b>Access</b>
                                    </Link>{" "} Page. </p>
                                    <p>2. Under Request Access section, enter the eid of the user who needs access.</p>
                                    <p>3. Enter the Private DNS of the instance for which the access should be added and click on "Request Access". </p>
                                    <p>4. You can view the request data under "Pending Approvals" in the same page.</p>
                                    <p>5. Click on "Approve" button.</p>

                                </AccordionPanel>

                            </AccordionItem>

                            <AccordionItem>
                                <h2>
                                    <AccordionButton _expanded={{ bg: '#805AD5', color: 'white' }}>
                                        <Box as="span" flex='1' textAlign='left' style={{ padding: "6px" }}>
                                            How can I register my SSH Key?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} style={{ paddingTop: "20px", paddingBottom: "25px" }}>
                                    <p>1. Navigate to <Link
                                        href={rootUrl + "/ssh"}
                                        rel="noopener noreferrer"
                                        style={{ color: "#9f7aea" }}
                                    >
                                        <b>Access</b>
                                    </Link>{" "}
                                    </p>
                                    <p>2. Under SSH Key, click on “Register”. This will open a Dialog.</p>
                                    <p>3. Fill in the expected information and register.</p>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <h2>
                                    <AccordionButton _expanded={{ bg: '#805AD5', color: 'white' }}>
                                        <Box as="span" flex='1' textAlign='left' style={{ padding: "6px" }}>
                                            How do I raise request for VPN access?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} style={{ paddingTop: "20px", paddingBottom: "25px" }}>
                                    <p>1. Go to  <Link
                                        href="https://mywizard-aiops.accenture.com/resources-3/support-requests/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#9f7aea" }}
                                    >
                                        <b>AiOps Support Portal</b>
                                    </Link>{" "} to raise a Service Request.
                                    </p>
                                    <p>2. Select Application -&gt; myWizard AiOps</p>
                                    <p>3. Fill in the expected information and register.</p>
                                    <p><i>Refer this <Link
                                        href="https://mywizard-aiops.accenture.com/user-guide-to-raise-a-service-request/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#9f7aea" }}
                                    >
                                        <b>guide</b>
                                    </Link>{" "}</i> to raise service requests.</p>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <h2>
                                    <AccordionButton _expanded={{ bg: '#805AD5', color: 'white' }}>
                                        <Box as="span" flex='1' textAlign='left' style={{ padding: "6px" }}>
                                            How can I access the server?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} style={{ paddingLeft: "4%", paddingTop: "20px", paddingBottom: "25px" }}>
                                    <ul>
                                        <li>
                                            <p>Connect to the VPN. If you don't have VPN access, please refer to the FAQ on <i>"How to raise request for VPN access?"</i> before proceeding with below steps.</p>
                                        </li><br></br>
                                        <li>
                                            <p>Open the gitbash or command prompt.</p>
                                        </li><br></br>
                                        <li>
                                            <p>To access bastion,</p>
                                            <p><b>ssh -i &lt;private-key-name&gt; &lt;username&gt;@nvibstsbxmg.mywizardaiops-labs.com -p 443</b></p>
                                            <p>ex: ssh -i poornnakey poornna.a.ganeshan@nvibstsbxmg.mywizardaiops-labs.com -p 443</p>
                                        </li><br></br>
                                        <li>
                                            <p>To access servers,</p>
                                            <p><b>ssh -o ProxyCommand="ssh -i &lt;private-key-name&gt; -W %h:%p &lt;username&gt;@nvibstsbxmg.mywizardaiops-labs.com -p 443" -i &lt;private-key-name&gt; &lt;username&gt;@&lt;ip-address-of-the-server&gt; -p 22</b></p>
                                            <p>ex: ssh -o ProxyCommand="ssh -i poornnakey -W %h:%p poornna.a.ganeshan@nvibstsbxmg.mywizardaiops-labs.com -p 443" -i poornnakey poornna.a.ganeshan@ip-10-238-24-221.ec2.internal -p 22</p>
                                        </li>
                                    </ul>
                                </AccordionPanel>

                            </AccordionItem>

                            <AccordionItem>
                                <h2>
                                    <AccordionButton _expanded={{ bg: '#805AD5', color: 'white' }}>
                                        <Box as="span" flex='1' textAlign='left' style={{ padding: "6px" }}>
                                            What to do if the URL is not loading?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} style={{ paddingTop: "20px", paddingBottom: "25px" }}>
                                    <p>Please contact our support team by logging a ticket with "myWizard AiOps" application or contact L3 support myWizAiOps.L3ATCISupport.</p>
                                    <p>Use this <Link
                                        href="https://mywizard-aiops.accenture.com/user-guide-to-raise-a-service-request/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#9f7aea" }}
                                    >
                                        <b>Link</b>
                                    </Link>{" "} To Raise A Service Request
                                    </p>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <h2>
                                    <AccordionButton _expanded={{ bg: '#805AD5', color: 'white' }}>
                                        <Box as="span" flex='1' textAlign='left' style={{ padding: "6px" }}>
                                            What to do if you didn't receive email of the lab details after 30 minutes of submitting a lab request?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} style={{ paddingTop: "20px", paddingBottom: "25px" }}>
                                    <p>Please contact our support team by logging a ticket with "myWizard AiOps" application or contact L3 support myWizAiOps.L3ATCISupport.</p>
                                    <p>Use this <Link
                                        href="https://mywizard-aiops.accenture.com/user-guide-to-raise-a-service-request/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#9f7aea" }}
                                    >
                                        <b>Link</b>
                                    </Link>{" "} To Raise A Service Request
                                    </p>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <h2>
                                    <AccordionButton _expanded={{ bg: '#805AD5', color: 'white' }}>
                                        <Box as="span" flex='1' textAlign='left' style={{ padding: "6px" }}>
                                            Common errors & it's troubleshooting steps
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} style={{ paddingLeft: "4%", paddingTop: "20px", paddingBottom: "25px" }}>
                                    <ul>
                                        <li><p>Connection timed out or software caused connection abort</p>
                                            <p>1. Check if you are connected to the VPN or restart it.</p>

                                            <p>2. If you are connecting with AWS VPN, use private ip in the ssh command.</p>

                                            <p>3. If you are connecting with Pulse VPN, use public ip (or) nvibstsbxmg.mywizardaiops-labs.com in the ssh command.</p>
                                            <br></br>
                                        </li><li>
                                            <p>Permission denied (public key)</p>
                                            <p>1. Make sure you have registered your key and requested access for the server.</p>

                                            <p>2. Ensure you are not using “aaam_” in your username in the ssh command as well as in the config file.</p>
                                        </li>
                                    </ul>
                                </AccordionPanel>

                            </AccordionItem>

                        </Accordion>
                    </CardHeader>
                </Card>
            </Grid>
            <br></br><br></br><br></br>
        </Flex >
    );
}
