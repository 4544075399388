// Chakra imports
import {
  Button,
  Flex,
  Icon,
  Spacer,
  Text,
  useColorModeValue, Input,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import IconBox from "components/Icons/IconBox";
import React, { useEffect, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PaymentMethod = ({ title, mastercard, visa , description, lastUpdatedDate}) => {
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const textColor = useColorModeValue("purple.700", "white");
  const borderColor = useColorModeValue("#dee2e6", "gray.500");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "purple.800"
  );

  const baseURL = "https://api-cam-training.mywizard-aiops.com";

  const idToken = localStorage.getItem("token");


  const [eid, setEid] = useState(localStorage.getItem("eid"));
  const [ip, setIp] = useState("");
  const [validIP, setValidIP] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleIp = (value) => {
    const ip = value.trim()
    const re = /^ip-.*?\.ec2\.internal$/;
    if (re.test(ip)) {
      setIp(ip)
      setValidIP(true)
    } else {
      setIp(ip)
      setValidIP(false)
    }
  }

  const handleEID = (value) => {
    const eid = value.trim()
    setEid(eid);
  }

  const handleRequestAccess = () => {
    setLoading(true)
    const urls = `${baseURL}/ssh-management/requests`;
    const submitData = { private_dns: ip, requester: eid };
    if(eid === "" || ip === ""){
      toast.error("Please enter all the fields.")
      setLoading(false)
    } else if (validIP === false) {
      toast.error("Please enter a valid Private DNS.")
      setLoading(false)
    } else {
      Axios
        .post(urls, submitData, {
          headers: { Authorization: idToken },
          params: {
            requester: eid,
            private_dns: ip,
            inititive: "na",
          },
        }).then((res) => {
          document.getElementById("ipInput").value = "";
          toast.success("Request submitted successfully!")
        })
        .catch((err) => {
          toast.error("ERROR! Please try again after sometime.");
          console.log("Async error", err);
          // setLoading(false)
        }).finally(() => {
          setLoading(false)
        });
    }


  };

  const options = {
    method: "POST",
    headers: {
      Authorization: idToken,
    },
  };


  return (
    <Card p='16px' mt='24px'>
      {(lastUpdatedDate !== "loading..." && lastUpdatedDate === "Not registered") ? 
      <p style={{color:"red", fontSize:"1rem", fontWeight:"500"}}>Your keys are not registered! Please register using the REGISTER KEY button above to request access.</p> : ""}
      <CardHeader>
        <Flex justify='space-between' align='center' minHeight='60px' w='100%'>
          <Text fontSize='lg' color={textColor} fontWeight='bold'>
            {title} 
          </Text>
        </Flex>
      </CardHeader>
      <Text fontSize='16px' color={textColor} mt={-3}>
        {description}
      </Text><br></br>
      {/* <ToastContainer autoClose={4000}/> */}
      <CardBody mb={5}>
        <Flex
          direction={{ sm: "column", md: "row" }}
          align='center'
          w='100%'
          borderRadius='15px'
          justify='center'
          // border='1px solid'
          // borderColor={borderColor}
          py='1rem'
          mb={{ sm: "24px", md: "0px" }}
          me={{ sm: "0px", md: "24px" }}
          gap={2}
          style={{marginRight: "38px"}}
          >

          {/* <IconBox me='10px' w='25px' h='22px'>
            {mastercard.icon}
          </IconBox> */}
          <Input
            fontSize="13px"
            py="11px"
            placeholder="EID"
            borderRadius="inherit"
            defaultValue={mastercard.number}

            onChange={event => handleEID(event.currentTarget.value)}
          />
          {/* <Text fontSize='md' fontWeight='semibold'>
              {mastercard.number}
            </Text> */}
          <Spacer />
          {/* <Button
            p='0px'
            bg='transparent'
            w='16px'
            h='16px'
            variant='no-hover'> */}
            {/* <Icon as={FaPencilAlt} /> */}
          {/* </Button> */}



          <Input
            id="ipInput"
            fontSize="13px"
            py="11px"
            placeholder="Private DNS of the server ... "
            borderRadius="inherit"
            onChange={event => handleIp(event.currentTarget.value)}
          />
          <Spacer />
          {/* <Button
            p='0px'
            bg='transparent'
            w='16px'
            h='16px'
            variant='no-hover'
          >
          </Button> */}


        </Flex>
        <Flex
          p='2px'
          bg='transparent'
          borderRadius='1px'
          width='20%'
          borderColor={borderColor}
          align='center'
          style={{marginRight: "20px"}}
          >
          {/* <Button bg={bgButton} color='white' fontSize='xs' variant='no-hover'  onClick={handleRequestAccess} isLoading ={loading}> */}
          <Button colorScheme="purple"
            variant="outline"
            type='submit'
            // fontSize='20px'
            w='30%'
            h='45'
            mb='24px'
            justifyContent='center'
            _hover={{
              bg: "purple.200",
              color: 'white'
            }}
            _active={{
              bg: "purple.400",
              color: 'white'
            }}
            style={{ marginBottom: 0, width: "auto", height: "42px",margin: "-19px", borderRadius: "12px", fontSize: "16px" }}
            // style={{ marginBottom: 0, width: "auto", margin: "-19px", borderRadius: "12px"}}
            onClick={handleRequestAccess} 
            isLoading ={loading}
            disabled={lastUpdatedDate == "Not registered" ? true : false}

          >
            REQUEST ACCESS
          </Button>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default PaymentMethod;
