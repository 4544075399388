// Chakra imports
import { Box, Flex, Grid, Icon } from "@chakra-ui/react";
import { connect } from "react-redux";

// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import { MastercardIcon, VisaIcon } from "components/Icons/Icons";
import { PhoneIcon, AddIcon, WarningIcon, LockIcon, BellIcon, ViewIcon } from '@chakra-ui/icons'
import React, { useEffect, useState } from "react";
import { FaPaypal, FaWallet, FaTrashAlt } from "react-icons/fa";
import { RiMastercardFill } from "react-icons/ri";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { MdPersonAddAlt1 } from "react-icons/md";
import {
  billingData,
  invoicesData,
  newestTransactions,
  olderTransactions,
  ApprovalsData
} from "variables/general";
import BillingInformation from "./components/BillingInformation";
import CreditCard from "./components/CreditCard";
import Invoices from "./components/Invoices";
import PaymentMethod from "./components/PaymentMethod";
import PaymentStatistics from "./components/PaymentStatistics";
import Transactions from "./components/Transactions";
import Axios from "axios";
import { tokenValidation } from "helper/customizedHooks";
import Approvals from "./components/Approvals";


const Billing = ({
  exp,
  email,
  isAuthenticated,
  loadingAuthentication,
  ...rest
}) => {
  const baseURL = "https://api-cam-training.mywizard-aiops.com";

  const idToken = localStorage.getItem("token");

  const [approvalsCount, setApprovalsCount] = useState("0");
  const [requestsCount, setRequestsCount] = useState("0");
  const [lastUpdatedDate, setLastUpdatedDate] = useState("loading...");
  const [validTill, setValidTill] = useState("loading...");
  const [myRequestedApprovals, setMyRequestedApprovals] = useState();
  const [myPendingApprovals, setMyPendingApprovals] = useState();
  const [myAllRequestedApprovals, setMyAllRequestedApprovals] = useState();

  useEffect(() => {
    setInterval(() => {
      tokenValidation();
    }, 4000);
  }, []);

  const eid = localStorage.getItem("eid")
  useEffect(() => {
    let timer;
    if (idToken) {
      getSSHCounts();
      timer = setInterval(() => {
        const apiCall = () => {
          getSSHCounts();
        };
        apiCall();
      }, 20000);
      return () => clearInterval(timer);
    }

  }, [idToken]);

  const getSSHCounts = async () => {
    const urls = baseURL + "/ssh-management/requests?inititive=na";
    Axios.get(urls, options).then((response) => {
      // setActiveSessionCount(response.data);
      // setApprovalsCount(response.data.approved)
      // setRequestsCount(response.data.pending)
      setApprovalsCount(response.data.pending_approvals.length)
      setRequestsCount(response.data.requested_approvals.length)
      setLastUpdatedDate(response.data.lastUpdatedDate)
      const validTill = response.data.remaining_days
      const remaining_days = validTill.split(",", 1);
      setValidTill(remaining_days[0])
      setMyRequestedApprovals(response.data.requested_approvals)
      setMyPendingApprovals(response.data.pending_approvals)
      setMyAllRequestedApprovals(response.data.all_requested_approvals)
    }).catch((err) => {
      //toast.error("ERROR! Please try again after sometime.");
      console.log("Async error", err);
    });
  };


  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ sm: "1fr", lg: "2fr 1.2fr" }} templateRows='1fr'>
        <Box>
          <Grid
            templateColumns={{
              sm: "2fr",
              md: "1fr 1fr",
              xl: "1fr 1fr 1fr 1fr",
            }}
            templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
            gap='26px'>
            <CreditCard
              backgroundImage={BackgroundCard1}
              title={"SSH Key"}
              number={""}
              validity={{
                name: "Last Updated",
                date: lastUpdatedDate,
              }}
              cvv={{
                name: "Valid Until",
                code: validTill
              }}
              buttonValue={lastUpdatedDate == "Not registered" ? "Register Key" : "Update Key"}
              icon={
                <LockIcon
                  w='24px'
                  h='auto'
                  color='purple.400'
                />
              }
            />
            <PaymentStatistics
              // icon={<BellIcon h={"24px"} w={"24px"} color='white' />}
              icon={<Icon as={BsFillPersonCheckFill} h={"24px"} w={"24px"} color='white' />}
              title={"Approvals"}
              description={"Pending Approvals"}
              amount={approvalsCount}
            />
            <PaymentStatistics
              // icon={<ViewIcon h={"24px"} w={"24px"} color='white' />}
              icon={<Icon as={MdPersonAddAlt1} h={"24px"} w={"24px"} color='white' />}
              title={"Requests"}
              description={"Pending Requests"}
              amount={requestsCount}
            />
          </Grid>
          <PaymentMethod
            title={"Request Access"}
            description={"Please input the privated dns details you need access and reach out to instance owner for approval."}
            mastercard={{
              number: eid,
            }}
            visa={{
              number: "Provide IP address of the server here",
            }}
            lastUpdatedDate={lastUpdatedDate}
          />
        </Box>
        { /*  <Invoices title={"Invoices"} data={invoicesData} /> */}
      </Grid>
      <Grid templateColumns={{ sm: "1fr", lg: "1.6fr 1.2fr" }} templateRows='1fr'>
        {/* <BillingInformation title={"Billing Information"} data={ApprovalsData} /> */}
        <Approvals
          title={"My Approvals"}
          requestedApprovals={myRequestedApprovals}
          pendingApprovals={myPendingApprovals}
          allRequestedApprovals={myAllRequestedApprovals}
        />

      </Grid>

      {/* <Grid templateColumns={{ sm: "1fr", lg: "1.6fr 1.2fr" }}>
        <BillingInformation title={"Billing Information"} data={billingData} />
        <Transactions
          title={"Your Transactions"}
          date={"23 - 30 March"}
          newestTransactions={newestTransactions}
          olderTransactions={olderTransactions}
        />
      </Grid>
      */}
    </Flex>
  );
}
const MSTP = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken
});

export default connect(MSTP)(Billing);