import React from "react";

import "./Pagination.css"
import { Button } from "@chakra-ui/react";
import { useState } from "react";

const Pagination = ({
    totalPosts,
    postsPerPage,
    setCurrentPage,
    currentPage,
}) => {
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

    let pages = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pages.push(i);
    }
    

    const renderPageNumbers = pages.map((page, index) => {
        if (totalPosts == 1) {
            return " "
        } else {
            if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
                return (
                    <div>
                        <button
                            key={index}
                            onClick={() => setCurrentPage(page)}
                            className={page == currentPage ? "active" : ""}>
                            {page}
                        </button>
                    </div>
                );
            } else {
                return null;
            }
        }

    });

    const handleNextBtn = () => {
        setCurrentPage(currentPage + 1)

        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    }

    const handlePrevbtn = () => {
        setCurrentPage(currentPage - 1);

        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };


    return (
        <div className='pagination'>
            {
                totalPosts == 1 ? " " :
                    <>
                        <Button
                            onClick={handlePrevbtn}
                            disabled={currentPage == pages[0] ? true : false}
                        >
                            &lt;
                        </Button>
                        {renderPageNumbers}
                        <Button
                            onClick={handleNextBtn}
                            disabled={currentPage == pages[pages.length - 1] ? true : false}
                        >
                            &gt;
                        </Button>
                    </>

            }

            {/* {
                totalPosts == 1 ? " " :
                    pages.map((page, index) => {
                        return (
                            <div>
                                <button
                                    key={index}
                                    onClick={() => setCurrentPage(page)}
                                    className={page == currentPage ? "active" : ""}>
                                    {page}
                                </button>
                            </div>
                        );
                    })
            } */}
        </div>
    );
};

export default Pagination;