// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  CircularProgress,
  Center,
  Divider,
  Flex,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesTableRow from "components/Tables/TablesTableRow";
import React, { useState } from "react";
import ATR150 from "assets/img/aiops/ATR150.png";
import peopleimage from "assets/img/aiops/stack.png";
import Pagination from "views/Dashboard/Billing/components/Pagination";
import LabHistory from "./LabHistory";

const Authors = ({ title, captions, data, Loading }) => {
  const textColor = useColorModeValue("purple.700", "white");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  return (
    <Card p='16px' mt='24px' overflowX={{ sm: "scroll", xl: "hidden" }} >
      <CardHeader p='6px 0px 22px 0px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          {title}
        </Text>

      </CardHeader>
      <CardBody>
        <Flex direction='column' w='100%'>
          <LabHistory title={"Lab Histoy"} captionItems={captions} data={data ? data.slice(firstPostIndex, lastPostIndex) : undefined} isLoading={Loading} />

          {data ?
            data.length > 0 ? <Pagination
              totalPosts={data ? data.length : 1}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            /> : " "
            : " "}
        </Flex>
      </CardBody>
    </Card>
  );
};

export default Authors;
