// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TimelineRow from "components/Tables/TimelineRow";
import React from "react";

const OrdersOverview = ({ title, amount, data }) => {
  const textColor = useColorModeValue("purple.700", "white");

  return (
    <Card maxH='100%'>
      <CardHeader p='22px 0px 25px 14px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
          <Text fontSize='16px' color={textColor} mt={-1}>
          To SSH (Secure Shell) into a server, you'll need to follow the below steps in <span style={{ color: "#9f7aea" }}><b>Access</b></span> page:
      </Text><br></br>
          
        </Flex>
      </CardHeader>
      <CardBody ps='20px' pe='0px' mb='10px' position='relative'>
        <Flex direction='column'>
          {data.map((row, index, arr) => {
            return (
              <TimelineRow
                key={row.title}
                logo={row.logo}
                title={row.title}
                date={row.date}
                color={row.color}
                index={index}
                arrLength={arr.length}
              />
            );
          })}
        </Flex>
      </CardBody>
    </Card>
  );
};

export default OrdersOverview;
