import {
    Flex, Text, useColorModeValue, Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Switch,
    useDisclosure,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Separator } from "components/Separator/Separator";
import React, { useEffect, useState } from "react";
import "../../Tables/components/Custom.css";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { InfoIcon } from "@chakra-ui/icons";


const PlainInstanceForm = ({ icon, title, description, amount }) => {
    const textColor = useColorModeValue("purple.700", "white");
    const bgColor = useColorModeValue("white", "purple.700");
    const btnRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [showModal, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const eid = localStorage.getItem("eid");
    const currentTime = moment().format();
    const tentativeServiceEndDate = moment()
        .add(7, "days")
        .format("YYYY-MM-DD");

    const [orderUId, setOrderUId] = useState("");
    const [orderItemUId, setOrderItemUId] = useState("");
    const [labName, setLabname] = useState("[ __ ]");
    const [validLabName, setValidLabName] = useState(false);
    const [port, setPort] = useState("8000");
    const [customURL, setCustomURL] = useState("https://dh1.mywizardaiops-labs.com/");
    const [proxyURL, setProxyURL] = useState("https://dh1.mywizardaiops-labs.com/[ __ ]");
    const [trainingApprover, setTrainingApprover] = useState("");
    const [notes, setNotes] = useState("");
    const [toggleOne, setToggleOne] = useState("false");
    const [toggleTwo, setToggleTwo] = useState("false");
    const [toggleThree, setToggleThree] = useState("false");
    const [loading, setLoading] = useState(false);

    const idToken = localStorage.getItem("token");

    const baseURL = "https://api-cam-training.mywizard-aiops.com";
    useEffect(() => {
        setOrderUId(uuidv4());
        // Using a different generation for this value due to new requirements.
        setOrderItemUId(uuidv4());
    }, []);

    const handleChangeLabname = (value) => {
        if (value === "") {
            setLabname("[ __ ]")
            setCustomURL("https://dh1.mywizardaiops-labs.com/")
            handleChangePort(port, "[ __ ]")
            setValidLabName(false)
        } else {
            const re = /^[a-z0-9]*$/;
            if (re.test(value)) {
                setLabname(value);
                setValidLabName(true)
                const url = "https://dh1.mywizardaiops-labs.com/" + value;
                setCustomURL(url)
                handleChangePort(port, value)
            }
            else {
                setValidLabName(false)
            }
            // setLabname(value);
            // const url = "https://dh1.mywizardaiops-labs.com/" + value;
            // setCustomURL(url)
            // handleChangePort(port, value)
        }
    };

    const handleChangePort = (value, labName) => {
        if (value === "") {
            setPort("8000")
            setProxyURL("https://dh1.mywizardaiops-labs.com/")
        } else if (value === "443") {
            setPort(value);
            setProxyURL("https://dh1.mywizardaiops-labs.com/")
        } else if (value === "80") {
            setPort(value);
            setProxyURL("http://dh1.mywizardaiops-labs.com/")
        } else if (value === "8000") {
            setPort(value);
            const url8000 = "https://dh1.mywizardaiops-labs.com/" + labName
            setProxyURL(url8000)
        } else if (value === "8088") {
            setPort(value);
            const url8088 = "https://dh1.mywizardaiops-labs.com/" + labName + "-mypmo"
            setProxyURL(url8088)
        } else if (value === "8089") {
            setPort(value);
            const url8089 = "https://dh1.mywizardaiops-labs.com/rest-" + labName
            setProxyURL(url8089)
        } else if (value === "7009") {
            setPort(value);
            const url7009 = "https://dh1.mywizardaiops-labs.com/atr-" + labName
            setProxyURL(url7009)
        } else if (value === "6000") {
            setPort(value);
            const url6000 = "http://dh1.mywizardaiops-labs.com/" + labName + "-model1"
            setProxyURL(url6000)
        } else if (value === "6001") {
            setPort(value);
            const url6001 = "http://dh1.mywizardaiops-labs.com/" + labName + "-model2"
            setProxyURL(url6001)
        } else if (value === "6002") {
            setPort(value);
            const url6002 = "http://dh1.mywizardaiops-labs.com/" + labName + "-model3"
            setProxyURL(url6002)
        } else if (value === "6003") {
            setPort(value);
            const url6003 = "http://dh1.mywizardaiops-labs.com/" + labName + "-model4"
            setProxyURL(url6003)
        } else if (value === "6004") {
            setPort(value);
            const url6004 = "http://dh1.mywizardaiops-labs.com/" + labName + "-model5"
            setProxyURL(url6004)
        } else if (value === "6005") {
            setPort(value);
            const url6005 = "http://dh1.mywizardaiops-labs.com/" + labName + "-model6"
            setProxyURL(url6005)
        } else if (value === "4440") {
            setPort(value);
            const url4440 = "http://dh1.mywizardaiops-labs.com/rundeck-" + labName
            setProxyURL(url4440)
        } else {
            setPort(value);
        }
    };

    const checkLabNameExists = (value) => {
        const urls = baseURL + "/fui/orders?inititive=na";
        Axios.get(urls, {
            headers: { Authorization: idToken },
            params: {
                domain_context_root: value,
            }
        }).then((response) => {
            const input = response.data
            if (input === "true") {
                toast.error("Lab name already in-use!. Please use a different name.")
                setLoading(false)
            } else {
                handleSubmit();
            }
        }).catch((err) => {
            toast.error("ERROR! Please try again after sometime.");
            console.log("Async error", err);
        });
    }

    const handleValidate = () => {
        setLoading(true)
        if (labName === "" || trainingApprover === "" || notes === "" || toggleOne === "false" || toggleTwo === "false" || toggleThree === "false") {
            toast.error("Please enter all the fields.")
            setLoading(false)
        } else if (!validLabName) {
            toast.error("Lab name should contain only numbers and lower case letters")
            setLoading(false)
        } else {
            checkLabNameExists(labName);
        }
    };

    const handleSubmit = () => {
        setLoading(true)
        setOrderUId(uuidv4());
        // Using a different generation for this value due to new requirements.
        setOrderItemUId(uuidv4());
        const submitData = {
            OrderUId: `${orderUId}`,
            UserOrder: `${labName}`,
            ClientName: "myWizard AiOps",
            EndToEndUId: `${labName}`,
            DMSId: `${labName}`,
            DeployRegion: "UNKNOWN",
            CloudPlatform: "AWS",
            MyClient: "myWizard AiOps",
            OS: "CentOS",
            DomainContextRoot: `${labName}`,
            OrderItems: [
                {
                    ProjectId: `${labName}`,
                    OrderItemUId: `${orderItemUId}`,
                    CreatedBy: `${eid}`,
                    CreateDate: `${currentTime}`,
                    ModifiedBy: "",
                    ModifiedDate: "",
                    ResolvedBy: "",
                    ResolvedDate: "",
                    Status: "Placed",
                    ProductUId: "5b384be8-62bf-419f-bb10-2ff5287afd71",
                    VendorName: "CAM",
                    Notes: "",
                    E2EProjectId: "undefined",
                    Users: [{ Id: "UNKNOWN" }],
                    Config: [
                        {
                            Key: "EndToEndUId",
                            Value: `${labName}`,
                        },
                        {
                            Key: "DMSId",
                            Value: `${labName}`,
                        },
                        {
                            Key: "AAAMTermsConditions",
                            Value: "yes"
                        },
                        {
                            Key: "CustomerMobileNo",
                            Value: "UNKNOWN"
                        },
                        {
                            Key: "SAPContractID",
                            Value: `${labName}`,
                        },
                        {
                            Key: "DomainContextRoot",
                            Value: `${labName}`,
                        },
                        {
                            Key: "Port",
                            Value: `${port}`,
                        },
                        {
                            Key: "GeographicArea",
                            Value: "UNKNOWN"
                        },
                        {
                            Key: "GDPR",
                            Value: "no"
                        },
                        {
                            Key: "DeploymentRegion",
                            Value: "virginia"
                        },
                        {
                            Key: "BenefitsExpected",
                            Value: 1
                        },
                        {
                            Key: "Environment",
                            Value: "Sandbox"
                        },
                        {
                            Key: "Tier",
                            Value: ""
                        },
                        {
                            Key: "TentativeServiceEndDate",
                            Value: `${tentativeServiceEndDate}T00:00:00.000Z`
                        },
                        {
                            Key: "DailyDataVolume",
                            Value: 0
                        },
                        {
                            Key: "ConcurrentUsers",
                            Value: 0
                        },
                        {
                            Key: "CustomerEnterpriseID",
                            Value: `${eid}`,
                        },
                        {
                            Key: "WBSEcode",
                            Value: "UNKNOWN"
                        },
                        {
                            Key: "ClientName",
                            Value: "myWizard AiOps"
                        },
                        {
                            Key: "DeployRegion",
                            Value: "UNKNOWN"
                        },
                        {
                            Key: "VendorName",
                            Value: "CAM"
                        },
                        {
                            Key: "AdminDistributionGroup",
                            Value: "UNKNOWN"
                        },
                        {
                            Key: "Users",
                            Value: "UNKNOWN"
                        },
                        {
                            Key: "ServiceGroup",
                            Value: "UNKNOWN"
                        },
                        {
                            Key: "UsersDistributionGroup",
                            Value: "UNKNOWN"
                        },
                        {
                            Key: "RequestNotes",
                            Value: `${notes}`,
                        },
                        {
                            Key: "SecondaryPOC",
                            Value: `${trainingApprover}`
                        },
                        {
                            Key: "ConfigurationType",
                            Value: "full"
                        },
                        {
                            Key: "AaamPlatformType",
                            Value: "myWizard AiOps Plain Instance Platform"
                        },
                        {
                            Key: "ProductInstanceUrl",
                            Value: null
                        },
                        {
                            Key: "CloudPlatform",
                            Value: ""
                        },
                        {
                            Key: "MyClient",
                            Value: ""
                        },
                        {
                            Key: "BaseOS",
                            Value: ""
                        },
                        {
                            Key: "RDSMultiAZ",
                            Value: false
                        },
                        {
                            Key: "IsMultiZoneDeployment",
                            Value: false
                        },
                        {
                            Key: "DialogFlow",
                            Value: "disabled"
                        },
                        {
                            Key: "DialogFlowClientToken",
                            Value: ""
                        },
                        {
                            Key: "DialogFlowDevToken",
                            Value: ""
                        },
                        {
                            Key: "Initiative",
                            Value: "myWizard AiOps"
                        }
                    ],
                    DeliveryOptions: null,
                    ArtifactAttributes: null,
                    AccessLinkURL: "",
                    Training: "training",
                },
            ],
            __v: 0,
        };

        const urls = baseURL + "/provision/mywizard-aiops?inititive=na";

        Axios
            .post(urls, submitData, { headers: { Authorization: idToken } })
            .then((res) => {
                // toast.success("Request to lab session is submitted successfully!")
                onClose();
                handleShow();
            })
            .catch((err) => {
                toast.error("ERROR! Please try again after sometime.");
                console.log("Async error", err);
                onClose();
            }).finally(() => {
                setLoading(false)
                onClose();
            });
        setLabname("");
        setTrainingApprover("");
        setNotes("");
        setPort("8000")
        setToggleOne("false");
        setToggleTwo("false");
        setToggleThree("false");
        setValidLabName(false);

    };

    return (
        <div>
            <Card p='16px' display='flex' align='center' justify='center'>
                <CardBody>
                    <Flex direction='column' align='center' w='100%' py='14px'>
                        <Flex
                            direction='column'
                            m='14px'
                            justify='center'
                            textAlign='center'
                            align='center'
                            w='100%'>
                            <Text fontSize='lg' color={textColor} fontWeight='bold'>
                                {title}
                            </Text>
                            <Text
                                mb='24px'
                                fontSize='xs'
                                color='purple.400'
                                fontWeight='semibold'>
                                {description}
                            </Text>
                            <Separator />
                        </Flex>
                        <Button colorScheme="purple"
                            variant="outline"
                            type='submit'
                            fontSize='16px'
                            mb='24px'
                            justifyContent='center'
                            _hover={{
                                bg: "purple.200",
                                color: 'white'
                            }}
                            _active={{
                                bg: "purple.400",
                                color: 'white'
                            }}

                            ref={btnRef}
                            style={{ marginBottom: 0, width: "78px", height: "35px", borderRadius: "12px" }}
                            onClick={onOpen}
                        >
                            Start
                        </Button>
                        <Drawer
                            isOpen={isOpen}
                            placement='right'
                            onClose={onClose}
                            finalFocusRef={btnRef}
                        >
                            <DrawerOverlay />
                            <DrawerContent>
                                <DrawerCloseButton onClick={() => { setToggleOne("false"); setToggleTwo("false"); setToggleThree("false"); setTrainingApprover(""); setNotes(""); setLabname("[ __ ]"); setPort("8000"); setProxyURL("https://dh1.mywizardaiops-labs.com/[ __ ]") }} />
                                <DrawerBody>
                                    <Flex
                                        direction='column'
                                        w='800px'
                                        background='transparent'
                                        borderRadius='15px'
                                        p='40px'
                                        mx={{ base: "100px" }}
                                        bg={bgColor}
                                        boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                                        <Text
                                            fontSize='xl'
                                            color={textColor}
                                            fontWeight='bold'
                                            textAlign='center'
                                            mb='22px'>
                                            myWizard AiOps Plain Instance
                                        </Text>
                                        <Text
                                            fontSize='s'
                                            color={textColor}
                                            fontWeight='bold'
                                            textAlign='center'
                                            mb='22px'>
                                            Please provide the below information to get started
                                        </Text>
                                        <HStack spacing='15px' justify='center' mb='22px'>
                                        </HStack>
                                        <FormControl>
                                            <FormLabel ms='4px' fontSize='16px' fontWeight='normal'>
                                                Name*
                                            </FormLabel>
                                            <Input
                                                fontSize='sm'
                                                ms='4px'
                                                borderRadius='15px'
                                                type='text'
                                                placeholder='Provide a unique name to identify this lab'
                                                mb='24px'
                                                size='lg'
                                                onChange={event => handleChangeLabname(event.currentTarget.value)}
                                            />
                                            <FormLabel ms='4px' fontSize='13px' mt='-4' mb='25px' fontWeight='normal' >
                                                Custom URL: <a target="_blank" href={customURL} style={{ color: "#6b46c1" }}>https://dh1.mywizardaiops-labs.com/{labName}</a>
                                            </FormLabel>
                                            <FormLabel ms='4px' fontSize='16px' fontWeight='normal'>
                                                Training Approver*
                                            </FormLabel>
                                            <Select fontSize='16px'
                                                ms='4px'
                                                borderRadius='15px'
                                                type='email'
                                                placeholder='Please select your training approver who approved this training'
                                                mb='24px'
                                                size='lg'
                                                onChange={event => setTrainingApprover(event.currentTarget.value)}
                                            >
                                                <option value='naveen.kalasamudram'>naveen.kalasamudram</option>
                                                <option value='saravana.babu.raman'>saravana.babu.raman</option>
                                                <option value='sebastian.allocca'>sebastian.allocca</option>
                                                <option value='luke.higgins'>luke.higgins</option>
                                            </Select>
                                            <FormLabel ms='4px' fontSize='13px' mb='15px' fontWeight='400'>
                                                <InfoIcon w={4} h={4} mt={-1}></InfoIcon> Please note that the default port is set to 8000. You can change it in the dropdown below if needed.
                                            </FormLabel>
                                            <FormLabel ms='4px' fontSize='16px' fontWeight='normal'>
                                                Port
                                            </FormLabel>
                                            <Select fontSize='16px'
                                                ms='4px'
                                                borderRadius='15px'
                                                type='text'
                                                mb='24px'
                                                size='lg'
                                                defaultValue='8000'
                                                onChange={event => handleChangePort(event.currentTarget.value, labName)}
                                            >
                                                {/* <option value='443'>443</option>
                                            <option value='80'>80</option> */}
                                                <option value='8000'>8000</option>
                                                <option value='8088'>8088</option>
                                                <option value='8089'>8089</option>
                                                <option value='7009'>7009</option>
                                                <option value='6000'>6000</option>
                                                <option value='6001'>6001</option>
                                                <option value='6002'>6002</option>
                                                <option value='6003'>6003</option>
                                                <option value='6004'>6004</option>
                                                <option value='6005'>6005</option>
                                                <option value='4440'>4440</option>
                                            </Select>
                                            <FormLabel ms='4px' fontSize='13px' mt='-4' mb='25px' fontWeight='normal' >
                                                Proxy Setup: <a target="_blank" href={proxyURL} style={{ color: "#6b46c1" }}>{proxyURL}</a>
                                            </FormLabel>
                                            <FormLabel ms='4px' fontSize='16px' fontWeight='normal'>
                                                Notes*
                                            </FormLabel>
                                            <Input
                                                fontSize='sm'
                                                ms='4px'
                                                borderRadius='15px'
                                                type='text'
                                                placeholder='Notes / Comments'
                                                mb='24px'
                                                size='lg'
                                                onChange={event => setNotes(event.currentTarget.value)}
                                            />
                                            <FormControl display='flex' alignItems='center' mb='24px'>
                                                <Switch id='termsone' colorScheme='purple' me='10px' isRequired onChange={() => { termsone.checked ? setToggleOne("true") : setToggleOne("false") }} />
                                                <FormLabel htmlFor='remember-login' mb='0' fontWeight='normal' fontSize="16px">
                                                    I agree to Terms and Conditions
                                                </FormLabel>
                                            </FormControl>
                                            <FormControl display='flex' alignItems='center' mb='24px'>
                                                <Switch id='termstwo' colorScheme='purple' me='10px' isRequired onChange={() => { termstwo.checked ? setToggleTwo("true") : setToggleTwo("false") }} />
                                                <FormLabel htmlFor='remember-login' mb='0' fontWeight='normal' fontSize="16px">
                                                    I understand this is a training environment and i would not use/store Accenture confidential data in any format.
                                                </FormLabel>
                                            </FormControl>
                                            <FormControl display='flex' alignItems='center' mb='24px'>
                                                <Switch id='termsthree' colorScheme='purple' me='10px' isRequired onChange={() => { termsthree.checked ? setToggleThree("true") : setToggleThree("false") }} />
                                                <FormLabel htmlFor='remember-login' mb='0' fontWeight='normal' fontSize="16px">
                                                    I understand this is a training environment and i would not use/store Client's data in any format.
                                                </FormLabel>
                                            </FormControl>
                                            <Button colorScheme="purple"
                                                variant="outline"
                                                type='submit'
                                                fontSize='16px'
                                                w='30%'
                                                h='45'
                                                mb='24px'
                                                justifyContent='center'
                                                _hover={{
                                                    bg: "purple.200",
                                                    color: 'white'
                                                }}
                                                _active={{
                                                    bg: "purple.400",
                                                    color: 'white'
                                                }}
                                                onClick={handleValidate}

                                                isLoading={loading}
                                            >
                                                Submit
                                            </Button>
                                        </FormControl>
                                    </Flex>
                                </DrawerBody>
                            </DrawerContent>
                        </Drawer>
                    </Flex>
                </CardBody>
            </Card>
            <Modal isOpen={showModal} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />

                    <ModalBody>
                        <Flex
                            direction='column'
                            w='800px'
                            background='transparent'
                            borderRadius='15px'
                            p='40px'
                            mx={{ base: "100px" }}
                            bg={bgColor}
                        // boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'
                        >
                            <Text
                                fontSize='xl'
                                color={textColor}
                                fontWeight='bold'
                                textAlign='center'
                                mb='22px'>
                                Request to lab session is submitted successfully!
                            </Text>
                            <Text
                                fontSize='s'
                                color={textColor}
                                fontWeight='bold'
                                textAlign='center'
                                mb='22px'>

                                Thank you for your patience. We're currently preparing the lab environment for you, and it will take some time to ensure everything is set up perfectly.<br></br><br></br>

                                Rest assured, as soon as your lab is ready for use, you will receive a notification via email. This email will include details you need to access and start using your lab.<br></br><br></br>

                                Thank you for choosing our service!


                            </Text>

                        </Flex>
                    </ModalBody>


                </ModalContent>
            </Modal>
        </div>
    );
};

export default PlainInstanceForm;
