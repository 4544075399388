import * as authTypes from "./authTypes";
import update from "immutability-helper";

const initialState = {
  loadingAuthentication: true,
  isAuthenticated: false,
  isFetchingAuth: false,
  user: {
    username: "",
    email: "",
    groups: [],
    permissions: [],
    expirationDate: "",
  },
  idToken: null,
  accessToken: null,
  userDetails: {},
  camPermissions: {},
  activeAccount: "na",
  initiativesBelongTo: [],
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case authTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        isFetchingAuth: false,
        isAuthenticated: true,
        user: action.userData,
      };
    case authTypes.SAVE_TOKENS:
      const { idToken, accessToken } = action.data;
      return update(state, {
        idToken: { $set: idToken },
        accessToken: { $set: accessToken },
        // isAuthenticated: { $set: idToken ? true : false },
      });
    case authTypes.SAVE_USER_DETAILS:
      return update(state, {
        userDetails: { $set: action.data },
        isAuthenticated: { $set: action.isAuthenticated },
        loadingAuthentication: { $set: false },
        // isAuthenticated:
      });
    case authTypes.CAM_PERMISSIONS:
      return update(state, { camPermissions: { $set: action.data } });
    case authTypes.inititive:
      var account = "aaammg";
      var belongingIntiatives = ["aaammg", "pam","myindustry","mywizard_myindustry"];

      for (var i = 0; i < action.data.length; i++) {
        if (action.data[i] === "myWizAiOps.TechArch") {
          belongingIntiatives.push("mcc");
        }
      }

      var res = [...new Set(belongingIntiatives)];
      return update(state, {
        activeAccount: { $set: account },
        initiativesBelongTo: { $set: res },
      });

    default:
      return {
        ...state,
      };
  }
}

export default authReducer;
