// Chakra imports
import {
  Box, Flex, Spacer, Text, Badge, useColorModeValue, Button, useDisclosure, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch, Textarea
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TbExternalLink } from "react-icons/tb"




const CreditCard = ({
  backgroundImage,
  title,
  icon,
  number,
  validity,
  cvv,
  buttonValue,
}) => {
  const btnRef = React.useRef()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const textColor = useColorModeValue("purple.700", "white");
  const bgColor = useColorModeValue("white", "purple.700");
  const [pubKey, setPubKey] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [loading, setLoading] = useState(false);


  const idToken = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const eid = localStorage.getItem("eid");

  const baseURL = "https://api-cam-training.mywizard-aiops.com";


  const handleSubmitUpdate = async () => {
    setLoading(true)
    const urls = baseURL + "/ssh-management/users";
    const submitData = { email: email, public_key: pubKey };
    if (pubKey === "") {
      toast.error("Please enter all the fields.")
      setLoading(false)
    } else {
      Axios
        .put(urls, submitData, {
          headers: { Authorization: idToken },
          params: {
            email: email,
            public_key: pubKey,
            inititive: "na",
          },
        })
        .then((res) => {
          toast.success("Your key has been updated successfully!")
          onClose();
        })
        .catch((err) => {
          toast.error("ERROR! Please try again after sometime.");
          console.log("Async error", err);
          onClose();
        }).finally(() => {
          setLoading(false)
          onClose();
        });
      
    }
  };

  const handleSubmitRegister = async () => {
    setLoading(true)
    const urls = baseURL + "/ssh-management/users";
    const submitData = {
      email: email,
      public_key: pubKey,
      first_name: firstName,
      last_name: lastName,
      initiative: "myWizard AiOps",
      team: "training",
    };
    if (pubKey === "" || firstName === "" || lastName === "") {
      toast.error("Please enter all the fields.")
      setLoading(false)
    } else {
      Axios
        .post(urls, submitData, {
          headers: { Authorization: idToken },
          params: {
            email: email,
            public_key: pubKey,
            last_name: lastName,
            first_name: firstName,
            team: "training",
            inititive: "myWizard AiOps",
          },
        })
        .then((res) => {
          toast.success("Your key is registered successfully!")
          onClose();
        })
        .catch((err) => {
          toast.error("ERROR! Please try again after sometime.");
          console.log("Async error", err);
          onClose();
        }).finally(() => {
          setLoading(false)
          onClose();
        });
    }
  };

  const fetchPresignedUrl = (fileName) => {
    const idToken = localStorage.getItem("token");
    const urls = baseURL + "/faq";
    if (idToken) {
      Axios
        .get(
          urls +
          `/${fileName}?customer_enterprise_id=${eid}&inititive=na`,
          {
            headers: { Authorization: idToken },
          }
        )
        .then((res) => {
          window.open(res.data[0], "_blank");
        })
        .catch((err) => {

        });
    }
  };



  return (
    <Card
      backgroundImage={backgroundImage}
      backgroundRepeat='no-repeat'
      background='cover'
      bgPosition='10%'
      p='16px'
      h={{ sm: "220px", xl: "100%" }}
      gridArea={{ md: "1 / 1 / 2 / 3", xl: "1 / 1 / 2 / 3" }}>
      <CardBody h='100%' w='100%'>
        {/* <ToastContainer autoClose={4000} /> */}
        <Flex
          direction='column'
          color='white'
          h='100%'
          p='0px 10px 20px 10px'
          w='100%'>
          <Flex justify='space-between' align='center'>
            <Text fontSize='lg' fontWeight='bold'>
              {title}
            </Text>
            {icon}
          </Flex>
          <Spacer />
          <Flex direction='column'>
            <Box>
              <Text fontSize='xl' letterSpacing='2px' fontWeight='bold'>
                {number}
              </Text>
            </Box>
            <Flex mt='14px'>
              <Flex direction='column' me='10%'>
                <Text fontSize='16px'>{validity.name}</Text>
                <Text fontSize='14px' fontWeight='bold'>
                  {validity.date}
                </Text>
              </Flex>
              <Flex direction='column' me='20%'>
              {buttonValue == "Update Key" ? <div>
              <Text fontSize='16px'>{cvv.name}</Text>
                <Text fontSize='14px' fontWeight='bold'>
                  {cvv.code}
                </Text>
              </div> : ""}

                

              </Flex>
              <Flex direction='column'>
                {validity.date === "loading..." ? "" :
                <Button
                bg="purple.400"
                width="100px"
                // color={colorStatus}
                fontSize="13px"
                p="3px 10px"
                borderRadius="12px"
                _hover={{
                  bg: "white",
                  color: 'purple.400'
                }}
                style={{
                  borderRadius: "12px",
                }}
                value={buttonValue}
                onClick={onOpen}
              >
                {buttonValue.toUpperCase()}
              </Button>
                }
                
                {/* <ToastContainer autoClose={7000}/> */}
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalCloseButton />

                    <ModalBody>
                      <Flex
                        direction='column'
                        w='800px'
                        background='transparent'
                        borderRadius='15px'
                        p='40px'
                        mx={{ base: "100px" }}
                        bg={bgColor}
                      // boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'
                      >
                        <Text
                          fontSize='xl'
                          color={textColor}
                          fontWeight='bold'
                          textAlign='center'
                          mb='22px'>
                          {buttonValue == "Update Key" ? "Update Key" : "Register Key"}
                        </Text>
                        <Text
                          fontSize='s'
                          color={textColor}
                          fontWeight='bold'
                          textAlign='center'
                          mb='22px'>
                          Please insert the following information to {buttonValue == "Update Key" ? "update" : "register"} your SSH key
                        </Text>
                        <HStack spacing='15px' justify='center' mb='22px'>
                        </HStack>
                        {buttonValue == "Update Key" ?
                          <FormControl>
                            <FormLabel ms='4px' fontSize='16px' fontWeight='normal'>
                              ** Your public key can be found in the *.pub file of your SSH folder.
                            </FormLabel>
                            <Textarea
                              fontSize='sm'
                              ms='4px'
                              borderRadius='15px'
                              type='text'
                              placeholder='Insert Public Key'
                              mb='24px'
                              size='lg'
                              onChange={event => setPubKey(event.currentTarget.value)}
                            />
                            <FormLabel ms='4px' fontSize='14px' fontWeight='normal'>
                              Please refer to <Button
                                color="purple.200"
                                target="_blank" fontSize='15px' fontWeight='normal'
                                style={{backgroundColor:"white", padding:"0px"}}
                                onClick={() =>
                                  fetchPresignedUrl("ssh_instructions_training.pdf")
                                }                            >
                                {document.documentElement.dir === "rtl" ? "سيممبل" : "SSH Instructions"}
                              </Button> file to know on how to update an SSH Key.
                              {/* <Icon as={TbExternalLink}/>&nbsp; */}
                              
                            </FormLabel><br></br>
                            <Button colorScheme="purple"
                              variant="outline"
                              type='submit'
                              fontSize='16px'
                              w='30%'
                              h='45'
                              mb='24px'
                              justifyContent='center'
                              _hover={{
                                bg: "purple.200",
                                color: 'white'
                              }}
                              _active={{
                                bg: "purple.400",
                                color: 'white'
                              }}
                              isLoading={loading}
                              onClick={handleSubmitUpdate}
                            >
                              Submit
                            </Button>
                          </FormControl>
                          :
                          <FormControl>


                            <Input
                              fontSize='sm'
                              ms='4px'
                              borderRadius='15px'
                              type='text'
                              placeholder='First Name'
                              mb='24px'
                              size='lg'
                              onChange={event => setFirstName(event.currentTarget.value)}
                            />

                            <Input
                              fontSize='sm'
                              ms='4px'
                              borderRadius='15px'
                              type='text'
                              placeholder='Last Name'
                              mb='24px'
                              size='lg'
                              onChange={event => setLastName(event.currentTarget.value)}
                            />
                            <FormLabel ms='4px' fontSize='16px' fontWeight='normal'>
                              ** Your public key can be found in the *.pub file of your SSH folder.
                            </FormLabel>
                            <Textarea
                              fontSize='sm'
                              ms='4px'
                              borderRadius='15px'
                              type='text'
                              placeholder='Insert Public Key'
                              mb='24px'
                              size='lg'
                              onChange={event => setPubKey(event.currentTarget.value)}
                            />

                            <FormLabel ms='4px' fontSize='16px' fontWeight='normal'>
                              Please refer to <Button
                                color="purple.200"
                                target="_blank" fontSize='15px' fontWeight='normal'
                                style={{backgroundColor:"white", padding:"0px"}}
                                onClick={() =>
                                  fetchPresignedUrl("ssh_instructions_training.pdf")
                                }                            >
                                {document.documentElement.dir === "rtl" ? "سيممبل" : "SSH Instructions"}
                              </Button> file to know on how to generate an SSH Key.
                              {/* <Icon as={TbExternalLink}/>&nbsp; */}
                              
                            </FormLabel><br></br>
                            <Button colorScheme="purple"
                              variant="outline"
                              type='submit'
                              fontSize='16px'
                              w='30%'
                              h='45'
                              mb='24px'
                              justifyContent='center'
                              _hover={{
                                bg: "purple.200",
                                color: 'white'
                              }}
                              _active={{
                                bg: "purple.400",
                                color: 'white'
                              }}
                              isLoading={loading}
                              onClick={handleSubmitRegister}
                            >
                              Submit
                            </Button>
                          </FormControl>
                        }

                      </Flex>
                    </ModalBody>


                  </ModalContent>
                </Modal>

              </Flex>

            </Flex>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default CreditCard;
