/*eslint-disable*/
import React from "react";
import { Flex, Link, List, ListItem, Text, Image } from "@chakra-ui/react";
import PropTypes from "prop-types";
import MyWizard from "assets/img/aiops/footerLogo.png"

export default function Footer(props) {
  // const linkTeal = useColorModeValue("teal.400", "red.200");=
  return (
    <Flex
      flexDirection={{
        base: "column",
        // xl: "row",
        xl: "column"
      }}
      alignItems={{
        base: "center",
        // xl: "start",
        xl: "center"
      }}
      // justifyContent="space-between"
      justifyContent="center"
      px="30px"
      pb="20px"
    >
      <Text
        color="purple.400"
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}

      >
        &copy; {1900 + new Date().getYear()}{" "}
        <Text as="span">
          {document.documentElement.dir === "rtl"
            ? " "
            : "All Rights Reserved. Powered By Accenture."}
        </Text>
        { /* 
        <Link
          // color={linkTeal}
          color="purple.400"
          href="https://mywizard-aiops.accenture.com"
          target="_blank"
        >
          {document.documentElement.dir === "rtl"
            ? " توقيت الإبداعية"
            : "myWizard AiOps"}
        </Link>
        &
        <Link
          // color={linkTeal}
          color="purple.400"
          href="https://www.simmmple.com"
          target="_blank"
        >
          {document.documentElement.dir === "rtl" ? "سيممبل " : " Simmmple"}
      </Link> 

        {document.documentElement.dir === "rtl"
          ? "للحصول على ويب أفضل"
          : " for a better web"}  */ }
      </Text>
      <Image
        src={MyWizard}
        alt='chakra image'
        style={{ width: "27%"}}
        // minWidth={{ md: "100px", lg: "auto" }}
      />
      <List display="flex">
        <ListItem
          me={{
            base: "20px",
            md: "24px",
          }}
        >
          <Link color="purple.400" href="https://mywizard-aiops.accenture.com">
            {document.documentElement.dir === "rtl"
              ? "توقيت الإبداعية"
              : ("myWizard AiOps")}
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "24px",
          }}
        >
          <Link color="purple.400" href="https://appstore.mywizard-aiops.accenture.com">
            {document.documentElement.dir === "rtl" ? "سيممبل" : "App Store"}
          </Link>
        </ListItem>
        { /*
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            color="purple.400"
            href="#blog"
            href="https://creative-tim.com/blog"
          >
            {document.documentElement.dir === "rtl" ? "مدونة" : "Blog"}
          </Link>
        </ListItem>
        <ListItem>
          <Link
            color="purple.400"
            href="#license"
            href="https://www.creative-tim.com/license"
          >
            {document.documentElement.dir === "rtl" ? "رخصة" : "License"}
          </Link>
        </ListItem>
        */ }
      </List>
    </Flex>
  );
}
