// Chakra imports
import React, { useEffect, useState } from "react";

import {
  Flex,
  Grid,
  Image,
  SimpleGrid,
  GridItem,
  useColorModeValue,
  CircularProgress
} from "@chakra-ui/react";
// assets
import peopleImage from "assets/img/about.jpg";
import logoChakra from "assets/img/aiops/aiops_1.png";
import Axios from "axios";
import { connect } from "react-redux";
import { headers } from "helper/api";
import { tokenValidation } from "helper/customizedHooks";


// Custom icons
import {
  RocketIcon, GlobeIcon
} from "components/Icons/Icons.js";
import BuiltByDevelopers from "./components/BuiltByDevelopers";
import MiniStatistics from "./components/MiniStatistics";

import WorkWithTheRockets from "./components/WorkWithTheRockets";


//BillingDashboard > to be used as the active lab sessions
import SessionsInformation from "./components/SessionsInformation";
import { getUserDetails, saveTokens } from "redux/auth/authActions";
import {
  billingData
} from "variables/general";
import Transactions from "./components/Transactions";

const Dashboard = ({
  exp,
  email,
  isAuthenticated,
  loadingAuthentication,
  ...rest
}) => {
  const [activeSessionCount, setActiveSessionCount] = useState("0");
  const [activeSessions, setActiveSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  const idToken = localStorage.getItem("token");
  const userDetails = localStorage.getItem("userDetails");
  // console.log(idToken)
  // const customerEnterpriseId = email.email.split("@")[0];
  // console.log(customerEnterpriseId)

  const baseURL = "https://api-cam-training.mywizard-aiops.com";
  useEffect(() => {
    setInterval(() => {
      tokenValidation();
    }, 4000);
  }, []);



  useEffect(() => {
    let timer;
    if (idToken) {
      getActiveSessionsCount();
      getActiveSessions();
      timer = setInterval(() => {
        const apiCall = () => {
          getActiveSessionsCount();
          getActiveSessions();
        };
        apiCall();
      }, 4000);
      return () => clearInterval(timer);
    }

  }, [idToken]);

  const getActiveSessionsCount = async () => {
    const urls = baseURL + "/recommendations/costownerdetails?inititive=na";
    Axios.get(urls, options).then((response) => {
      setActiveSessionCount(response.data);
      localStorage.setItem("activeSession", response.data)// console.log(response)
    }).catch((err) => {
      //toast.error("ERROR! Please try again after sometime.");
      console.log("Async error", err);
    });
  };

  const getActiveSessions = async () => {
    const urls = baseURL + "/ec2/ec2instances?inititive=na";
    Axios.get(urls, options).then((response) => {
      setActiveSessions(response.data);
      setIsLoading(false)
      // console.log(response.data)

    }).catch((err) => {
      //toast.error("ERROR! Please try again after sometime.");
      console.log("Async error", err);
    });
  };

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const activeSession = localStorage.getItem("activeSession")
  const iconBoxInside = useColorModeValue("white", "white");

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl:4 }} spacing='44px'>
        <MiniStatistics
          title={'Active Sessions'}
          amount={activeSession ? activeSession : "0"}
          // amount='1 Running'
          percentage={55}
          icon={<RocketIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Available Labs"}
          amount={"6 Available"}
          percentage={5}
          icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
      </SimpleGrid>
      {isLoading ? (
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop:"5%"
        }}>
          <CircularProgress isIndeterminate color='#7500c0' />
        </div>
      ) : (
        <SimpleGrid columns={3} row={2} spacing={3} w="100%">
          <GridItem colSpan={2}
            style={{
              width: "83%"
            }}
          >
            <SessionsInformation title={"Active Lab Sessions"} data={activeSessions} isLoading={isLoading}/>
          </GridItem>
          <GridItem rowSpan={2}
            style={{
              width: "120%",
              right: "114px",
              position: "relative",
              height: "max-content",
              // top: "-1%"
            }}
          >
            <WorkWithTheRockets
              backgroundImage={peopleImage}
              title={"Who we are & what we're about"}
              description={
                "myWizard AiOps has been designed to deliver objectives that drive value for Accenture and our clients..."
              }
            />
          </GridItem>

          <GridItem colSpan={2}
            style={{
              width: "83%"
            }}
          >
            <BuiltByDevelopers
              title={"Recent Events"}
              name={"AiOps 2023 BootCamps"}
              description={
                "AiOps is running a bootcamp in Oct2023 in the following locations, if you are interested pls reach out to...."
              }
              image={
                <Image
                  src={logoChakra}
                  alt='bootcamp'
                  minWidth={{ md: "300px", lg: "auto" }}
                />
              }
            />
          </GridItem>
        </SimpleGrid>
        // <Grid templateColumns={{ sm: "1fr", lg: "1.6fr 1.2fr" }}
        //   templateRows={{ md: "1fr auto", lg: "1fr" }}
        //   my='24px'
        //   gap='24px'
        // >
        //   <SessionsInformation title={"Lab Sessions"} data={activeSessions} />
        //   <WorkWithTheRockets
        //     backgroundImage={peopleImage}
        //     title={"Who we are & what we're about"}
        //     description={
        //       "myWizard AiOps has been designed to deliver objectives that drive value for Accenture and our clients..."
        //     }
        //   />
        //   <BuiltByDevelopers
        //     title={"Recent Events"}
        //     name={"AiOps 2022 BootCamps"}
        //     description={
        //       "AiOps is running a bootcamp in dec2022 in the following locations, if you are interesred pls reach out to...."
        //     }
        //     image={
        //       <Image
        //         src={logoChakra}
        //         alt='bootcamp'
        //         minWidth={{ md: "300px", lg: "auto" }}
        //       />
        //     }
        //   />

        // </Grid>
      )}


      {/* commented by poornna */}
      {/* <Grid
        templateColumns={{ md: "1fr", lg: "1.6fr 1.2fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my='26px'
        gap='24px'>


      </Grid> */}


      { /*
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap='24px'
        mb={{ lg: "26px" }}>
        <ActiveUsers
          title={"Active Users"}
          percentage={23}
          chart={<BarChart />}
        />
        <SalesOverview
          title={"Sales Overview"}
          percentage={5}
          chart={<LineChart />}
        />
      </Grid>
    

      <Grid
        templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
        templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
        gap='24px'>
        <Projects
          title={"Projects"}
          amount={30}
          captions={["Companies", "Members", "Budget", "Completion"]}
          data={dashboardTableData}
        />
        <OrdersOverview
          title={"Orders Overview"}
          amount={30}
          data={timelineData}
        />
      </Grid>
        */ }

    </Flex>
  );
}
const MSTP = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken
});

export default connect(MSTP)(Dashboard);
