import React, {useEffect} from "react";
import RequestedApprovalsData from "./RequestedApprovalsData";
import { CircularProgress, Text } from "@chakra-ui/react";


const ApprovalsDataList = ({ approvalData }) => {
    return (
        <div className="approval_data_list">
            {
                approvalData ?
                    (approvalData.length !== 0 ?
                        approvalData.map((row) => {
                            return (
                                <RequestedApprovalsData
                                    request_id={row.request_id}
                                    private_dns={row.private_dns}
                                    instance_owner={row.instance_owner}
                                    requested_on={row.requested_on}
                                    status={row.status}
                                />
                            );
                        }) : <Text fontSize='sm' mt='2'>No requested approvals</Text>
                    ) :
                    <div style={{
                        width: '80%',
                        height: '80%',
                        display: 'flex'
                    }}>
                        <div style={{
                            width: '80%',
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'left',
                            marginTop: '2%'
                        }}>
                            <CircularProgress isIndeterminate color='#7500c0' size="2.5rem" />
                        </div>
                    </div>
            }
        </div>
    )
}

export default ApprovalsDataList;
