import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveTokens, getUserDetails } from "../redux/auth/authActions";
import PropTypes from "prop-types";


//Get Token Id from url
const getIdToken = (url) => {
  const getIdTokenArr = /id_token=.*access_token=.*expires_in/.exec(url);

  if (getIdTokenArr) {
    let getIdTokenArr1 = /id_token=.*access_token/.exec(url);
    return getIdTokenArr1
      ? getIdTokenArr1[0]
        .split("")
        .splice(9, getIdTokenArr1[0].length - 22)
        .join("")
      : getIdTokenArr;
  } else {
    const getIdTokenArrAcc2 = /id_token=.*token_type/.exec(url);
    return getIdTokenArrAcc2
      ? getIdTokenArrAcc2[0]
        .split("")
        .splice(9, getIdTokenArrAcc2[0].length - 20)
        .join("")
      : getIdTokenArrAcc2;
  }
};

//Get access Token from url
const getAccessToken = (url) => {
  const getAccessTokenArr = /access_token=.*expires_in=.*token_type/.exec(url);

  if (getAccessTokenArr) {
    const myArr = /access_token=.*expires_in/.exec(url);
    return myArr
      ? myArr[0]
        .split("")
        .splice(13, myArr && myArr[0].length - 24)
        .join("")
      : myArr;
  } else {
    const getIdTokenArrAcc2 = /access_token=.*id_token/.exec(url);
    return getIdTokenArrAcc2
      ? getIdTokenArrAcc2[0]
        .split("")
        .splice(13, getIdTokenArrAcc2[0].length - 22)
        .join("")
      : getIdTokenArrAcc2;
  }
};

// const getExpiresIn = url => {
//   // const getExpiresIn = /expires_in=.*\&token_type/;
//   // const getExpiresInAcc = /expires_in=.*/;
//   // const getExpiresInArr = getExpiresIn.exec(url);
//   // const getExpiresInArrACC = getExpiresInAcc.exec(url);

//   // if (getExpiresInArr) {
//   //   if(getExpiresInArr){
//   //     return getExpiresInArr[0]
//   //     .split("")
//   //     .splice(11, getExpiresInArr[0].length - 22)
//   //     .join("")
//   //   }
//   //   else{
//   //     return getExpiresInArr
//   //   }
//   // } else {
//   //   if(getExpiresInArrACC){
//   //     return getExpiresInArrACC[0]
//   //     .split("")
//   //     .splice(11)
//   //     .join("")
//   //   }
//   //   else{
//   //     return getExpiresInArrACC
//   //   }
//   // }
// };

//Function for getTokens
const getTokens = (url) => ({
  idToken: getIdToken(url),
  accessToken: getAccessToken(url),
  // expiresIn: getExpiresIn(url)
});

/**
 * The AuthPage Authenticate the user and save Token obj in localStorage as in Redux
 * @author Allison Haire
 * @version 1.0
 * @see Used in component: Routes.js
 */

//Function for Auth the user and save Token obj in localStorage as in Redux
const AuthPage = ({ history, saveTokenFunc, getUserDetails }) => {
  useEffect(() => {
    const url = window.location.href;
    const tokenObj = getTokens(url);
    localStorage.setItem("tokenObj", JSON.stringify(tokenObj));
    getUserDetails(tokenObj.idToken);
    localStorage.setItem("token", tokenObj.idToken);
    localStorage.setItem("accessToken", tokenObj.accessToken);
    saveTokenFunc(tokenObj);
    history.push("/admin/dashboard");
  });

  // const { ...rest } = props;
	// ref for the wrapper div
	const wrapper = React.createRef();
	// React.useEffect(() => {
	// 	document.body.style.overflow = 'unset';
	// 	// Specify how to clean up after this effect:
	// 	return function cleanup() {};
	// });
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].views);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].views);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					if (routes[i].secondaryNavbar) {
						return routes[i].secondaryNavbar;
					}
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.category === 'account') {
				return getRoutes(prop.views);
			}
			if (prop.layout === '/auth') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			} else {
				return null;
			}
		});
	};
	const navRef = React.useRef();
	document.documentElement.dir = 'ltr';

  return <div>Getting your login details...</div>;
};

AuthPage.propTypes = {
  /** ID Token */
  getIdToken: PropTypes.string,
  /** Access Token */
  accessToken: PropTypes.string,
  /** Function for save tokens*/
  saveTokens: PropTypes.func,
  /** Function for get user details */
  getUserDetails: PropTypes.func,
  /** Function for get tokens */
  getTokens: PropTypes.func,
};

export default withRouter(
  connect(null, { saveTokenFunc: saveTokens, getUserDetails: getUserDetails })(
    AuthPage
  )
);
