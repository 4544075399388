// chakra imports
import { Avatar, Flex, Text, useColorModeValue, Button } from "@chakra-ui/react";
import { ClockIcon } from "components/Icons/Icons";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";


export function ItemContent(props) {
  const navbarIcon = useColorModeValue("purple.500", "purple.200");
  const notificationColor = useColorModeValue("purple.700", "white");
  const spacing = " ";
  return (
    <>
      <Avatar
        name={props.aName}
        src={props.aSrc}
        borderRadius="12px"
        me="16px"
      />
      <Flex flexDirection="column">
        {/* <Text fontSize="14px" color={notificationColor}> */}
          {/* <Text fontWeight="bold" fontSize="16px"> */}
            <p style={{marginTop:"3%"}}>{props.boldInfo}</p>
            
          {/* </Text> */}
          {/* {props.info} */}
        {/* </Text> */}
        <NavLink to="/auth/signin">
          <Button
            ms="0px"
            px="0px"
            me={{ sm: "2px", md: "16px" }}
            // color={navbarIcon}
            variant="transparent-with-icon"
            style={{marginTop:"-3%"}}
          >
            Logout
          </Button>
         </NavLink>
        {/* <Flex alignItems="center">
          <ClockIcon color={navbarIcon} w="13px" h="13px" me="3px" />
          <Text fontSize="xs" lineHeight="100%" color={navbarIcon}>
            {props.time}
          </Text>
        </Flex> */}
      </Flex>
    </>
  );
}
