import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Link
} from "@chakra-ui/react";
import React from "react";

function TablesTableRow(props) {
  const { logo, domainContextRoot, accessLinkURL, module, aaamPlatformType, status, date } = props;
  const textColor = useColorModeValue("purple.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "purple.400");

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar src={logo} w="50px" borderRadius="12px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="16px"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {domainContextRoot ? domainContextRoot : "N/A"}
            </Text>

            <Text fontSize="sm" color="purple.400" fontWeight="normal">
              <p>
                <Link
                  href={accessLinkURL ? accessLinkURL : "#"} target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#9f7aea" }}
                >
                  {accessLinkURL ? accessLinkURL : "N/A"}
                </Link>{" "}
              </p>
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="16px" color={textColor} fontWeight="bold">
            {aaamPlatformType ? aaamPlatformType : "N/A"}
          </Text>
          <Text fontSize="sm" color="purple.400" fontWeight="normal">
            {module ? module : "N/A"}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Badge
          bg={status === "Fulfilled" ? "green.400" : bgStatus || status === "Stuck" ? "green.400" : bgStatus || status === "Terminated" ? "green.400" : bgStatus}
          color={status === "Stuck" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {/* { status ? status : "N/A" } */}
          {status === "Fulfilled" ? "In Progress" : "N/A" || status === "Terminated" ? "Completed" : "N/A"}
        </Badge>
      </Td>
      <Td>
        <Text fontSize="16px" color={textColor} fontWeight="bold" pb=".5rem">
          {date ? date.split("T")[0] : "N/A"}
        </Text>
      </Td>
      {/* <Td>
        <Button p="0px" bg="transparent" variant="no-hover">
          <Text
            fontSize="md"
            color="purple.400"
            fontWeight="bold"
            cursor="pointer"
          >
            Edit
          </Text>
        </Button>
      </Td> */}
    </Tr>
  );
}

export default TablesTableRow;
