// import
import Dashboard from "views/Dashboard/Dashboard";
import Tables from "views/Dashboard/Tables";
import Billing from "views/Dashboard/Billing";
import Bootcamps from "views/Dashboard/Bootcamps";
// import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import Faq from "views/Dashboard/Faq";
import {FaUserLock} from "react-icons/fa";
import {FaLaptopCode} from "react-icons/fa";
import { FaBimobject } from "react-icons/fa";



import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
  SettingsIcon
} from "components/Icons/Icons";
import { Icon } from "@chakra-ui/react";

const Routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/labs",
    name: "My Labs",
    rtlName: "لوحة القيادة",
    icon: <Icon as={FaLaptopCode}/>,
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/ssh",
    name: "My Access",
    rtlName: "لوحة القيادة",
    icon: <Icon as={FaUserLock}/>,
    component: Billing,
    layout: "/admin",
  },
  {
    path: "/bootcamps",
    name: "My Bootcamps",
    rtlName: "لوحة القيادة",
    icon: <Icon as={FaBimobject}/>,
    component: Bootcamps,
    layout: "/admin",
  },

  /*
  {
    path: "/rtl-support-page",
    name: "FAQ",
    rtlName: "آرتيإل",
    icon: <SupportIcon color="inherit" />,
    component: RTLPage,
    layout: "/rtl",
  },
  */
  {
    name: "SUPPORT",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/faq",
        name: "FAQ",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: Faq,
        layout: "/admin",
      },
      /* {
        path: "/profile",
        name: "Profile",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      }, */
      // {
      //   path: "/profile",
      //   name: "Support",
      //   rtlName: "لوحة القيادة",
      //   icon: <SupportIcon color="inherit" />,
      //   secondaryNavbar: true,
      //   component: Profile,
      //   layout: "/admin",
      // },
      /* {
         path: "/signin",
         name: "Sign In",
         rtlName: "لوحة القيادة",
         icon: <DocumentIcon color="inherit" />,
         component: SignIn,
         layout: "/auth",
       },
       {
         path: "/signup",
         name: "Sign Up",
         rtlName: "لوحة القيادة",
         icon: <RocketIcon color="inherit" />,
         secondaryNavbar: true,
         component: SignUp,
         layout: "/auth",
       }, */
    ],
  },
];


// export default connect(mapStateToProps, mapDispatchToProps)(dashRoutes);
export default Routes;



// import React, { useEffect, useState } from "react";

// // redux
// import { history } from "../src/index";
// import { connect } from "react-redux";
// import { ConnectedRouter } from "connected-react-router";
// import { Switch, Route, withRouter } from "react-router-dom";
// import {
//   HomeIcon,
//   StatsIcon,
//   CreditIcon,
//   PersonIcon,
//   DocumentIcon,
//   RocketIcon,
//   SupportIcon,
//   SettingsIcon
// } from "components/Icons/Icons";

// // Components
// // import
// import Dashboard from "views/Dashboard/Dashboard";
// import Tables from "views/Dashboard/Tables";
// import Billing from "views/Dashboard/Billing";
// // import RTLPage from "views/Dashboard/RTL";
// import Profile from "views/Dashboard/Profile";
// import SignUp from "views/Auth/SignUp.js";
// import PrivateRoute from "privateRoute";
// import Login from "views/Auth/Login.js";
// import Error404 from "components/error/Error404";


// import AuthLayout from "layouts/AuthPage.jsx";
// import AdminLayout from "layouts/Admin.js";
// import RTLLayout from "layouts/RTL.js";
// // import Dashboard from "layouts/Admin";




// const Routes = ({
//   exp,
//   email,
//   isAuthenticated,
// }) => {
  

 

  
//   const auth = localStorage.getItem("isRegistered");
//   const idToken = localStorage.getItem("token");

//   useEffect(() => {
//     exp && localStorage.setItem("exp", exp);
//   }, [exp]);



//   return (
//     <ConnectedRouter history={history}>
//         <Switch>
//           <Route
//             path="/"
//             exact
//             isAuthenticated={isAuthenticated}
//             component={Dashboard}
//             name= "Dashboard"
//             rtlName= "لوحة القيادة"
//             // icon= "<HomeIcon color="inherit" />"
//             // component: Dashboard,
//             layout= "/admin"
//           />

//           <Route path={`/auth`} component={AuthLayout} />
//           {/* <Route path={`/admin`} component={AdminLayout} /> */}
//           <Route path={`/rtl`} component={RTLLayout} />
//           {/* <Redirect from={`/`} to="/admin/dashboard" /> */}
//           <Route path="/login" component={withRouter(Login)} />
//           <Route path="/404" component={Error404} />
          
//         </Switch>
//     </ConnectedRouter>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: state.auth.isAuthenticated,
//     exp: state.auth.userDetails.exp,
//     iat: state.auth.userDetails.iat,
//     timeout: state.auth.timeout,
//     email: state.auth.userDetails.email,
//     adRole: state.auth.userDetails.profile,
//     camPermissions: state.auth.camPermissions,
//   };
// };


// export default connect(mapStateToProps)(Routes);
