export const headers = {
    "content-type": "application/json",
    Accept: "application/json",
  };
  
  export const getOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  