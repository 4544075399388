import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useDisclosure, Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Textarea,
  useColorModeValue,
  Badge,
  CircularProgress,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Divider,
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import { FaCopy } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { BsInfoCircle } from "react-icons/bs"
import 'react-toastify/dist/ReactToastify.css';


const BillingRow = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const textColor = useColorModeValue("purple.700", "white");
  const bgColor = useColorModeValue("#F8F9FA", "purple.800");
  const nameColor = useColorModeValue("gray.500", "white");
  const { inititive, instanceId, accountId, accountType, productType, clusterId, accessLinkUrl, startDate, status, privateDNS } = props;
  const bgStatus = useColorModeValue("purple.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "purple.400");

  const idToken = localStorage.getItem("token");
  const eid = localStorage.getItem("eid");
  const [startloading, setStartLoading] = useState(false);
  const [terminateloading, setTerminateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [connectModal, setConnectModal] = useState(false);

  // const statusUpdate = useCallback(() => {
  //   console.log("Status", status);
  //   setStartLoading(false);
  // }, [status])
  // useEffect(() => { statusUpdate() }, [status])

  const baseURL = "https://api-cam-training.mywizard-aiops.com";

  const pending = () => {
    status === "pending" ? setStartLoading(false) : " " || status === "pending" ? setTerminateLoading(false) : " "
  }
  useEffect(() => { pending() }, [status])

  const OpenConnectModal = () => {
    setConnectModal(true);
  }

  const handleClose = () => {
    setConnectModal(false);
  }

    // let platformtype
  // let accessLink
  // if (productType === "myWizard AiOps Managed Platform") {
  //   platformtype = "myWizard AiOps ATR Platform"
  //   accessLink = "https://dh1-testatrserver0010-atr.aiops-bootcamp.com/atr/signin"
  // } else if (productType === "myWizard AiOps Stack360") {
  //   platformtype = "myWizard AiOps Stack Suite"
  //   accessLink = "https://dh1.aiops-bootcamp.com/teststacksuiteserver10"
  // } else {
  //   platformtype = " "
  //   accessLink = "N/A"
  // }


  const handleDelete = async () => {
    // toast.info("ERROR! Please try again after sometime.");
    setLoading(true)
    setTerminateLoading(true)
    const urls = `${baseURL}/ec2/ec2instances/${instanceId}/terminate?userepid=${eid}&accountId=${accountId}&accountType=${accountType}&privateDNS=${privateDNS}&inititive=${inititive}`;
    Axios.get(urls, options).then((response) => {
      console.log(response)
      toast.success("Request to delete the lab session is successfully submitted!");
      onClose();
    }).catch((err) => {
      toast.error("ERROR! Please try again after sometime.");
      console.log("Async error", err);
      setTerminateLoading(false)
      onClose();
    }).finally(() => {
      setLoading(false)
      onClose();
    });
  };


  const handleStart = async () => {
    setStartLoading(true)

    const urls = `${baseURL}/ec2/ec2instances/${instanceId}/start?userepid=${eid}&accountId=${accountId}&accountType=${accountType}&privateDNS=${privateDNS}&inititive=${inititive}`;
    Axios.get(urls, options).then((response) => {
      console.log(response)
      toast.success("Request to start the lab session is successfully submitted!");
    }).catch((err) => {
      toast.error("ERROR! Please try again after sometime.");
      console.log("Async error", err);
      setStartLoading(false)
    });
  };

  const handleStop = async () => {
    setStartLoading(true)

    const urls = `${baseURL}/ec2/ec2instances/${instanceId}/stop?userepid=${eid}&accountId=${accountId}&accountType=${accountType}&privateDNS=${privateDNS}&inititive=${inititive}`;
    Axios.get(urls, options).then((response) => {
      // console.log(response)
      toast.success("Request to stop the lab session is successfully submitted!");
    }).catch((err) => {
      toast.error("ERROR! Please try again after sometime.");
      console.log("Async error", err);
      setStartLoading(false)
    });
  };

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  const setStatusColor = (status) => {
    switch (status) {
      case "running":
        return "green.400";
      case "stopped":
        return "red.400";
      case "pending":
        return "#ebcc34"

      default:
        return bgStatus;
    }
  }

  const fetchPresignedUrl = (fileName) => {
    const idToken = localStorage.getItem("token");
    const urls = baseURL + "/faq";
    if (idToken) {
      Axios
        .get(
          urls +
          `/${fileName}?customer_enterprise_id=${eid}&inititive=na`,
          {
            headers: { Authorization: idToken },
          }
        )
        .then((res) => {
          window.open(res.data[0], "_blank");
        })
        .catch((err) => {

        });
    }
  };

  const handleCopy1 = (value) => {
    const sshCommand = 'ssh -o ProxyCommand="ssh -i <private-key-name> -W %h:%p ' + eid + '@nvibstsbxmg.mywizardaiops-labs.com -p 443" -i <private-key-name> ' + eid + '@' + privateDNS + ' -p 22'
    const proxyHostName = "nvibstsbxmg.mywizardaiops-labs.com"
    const proxyHostPort = "443"
    // const hostName = { privateDNS }
    const hostPort = "22"
    // const user = { eid }
    if (value === 'sshComm') {
      navigator.clipboard.writeText(sshCommand);
    }
    else if (value === 'proxyhostname') {
      navigator.clipboard.writeText(proxyHostName);
    }
    else if (value === 'proxyhostport') {
      navigator.clipboard.writeText(proxyHostPort);
    }
    else if (value === 'hostname') {
      navigator.clipboard.writeText(privateDNS);
    }
    else if (value === 'hostport') {
      navigator.clipboard.writeText(hostPort);
    }
    else if (value === 'user') {
      navigator.clipboard.writeText(eid);
    }
    else {
      navigator.clipboard.writeText("");
    }
  };

  const handleDownloadConfig = () => {
    const idToken = localStorage.getItem("token");
    const urls = baseURL + "/ec2/sshConfig";
    const res = "ssh_config.txt"
    if (idToken) {
      Axios
        .get(
          urls,
          {
            headers: { Authorization: idToken },
            params: {
              customer_enterprise_id: eid,
              inititive: inititive,
              resource_name: res,
              accountId: accountId,

            },
          }
        )
        .then((res) => {
          window.open(res.data)
        })
        .catch((err) => {

        });
    }
  }

  return (
    <div>
      <Box p="24px" bg={bgColor} my="22px" borderRadius="12px">
        {/* <ToastContainer autoClose={4000}/> */}
        <Flex justify="space-between" w="100%">
          <Flex direction="column" maxWidth="70%">
            <Text color={nameColor} fontSize="md" fontWeight="bold" mb="10px">
              {productType?.toUpperCase()}
              {/* {platformtype?.toUpperCase()} */}
            </Text>
            <Text color="gray.400" fontSize="16px" fontWeight="semibold">
              Session Name:{" "}
              <Text as="span" color="gray.500" fontSize="16px">
                {clusterId}
              </Text>
            </Text>
            <Text color="gray.400" fontSize="16px" fontWeight="semibold">
              Private DNS:{" "}
              <Text as="span" color="gray.500" fontSize="16px">
                {privateDNS}
              </Text>
            </Text>
            <Text color="gray.400" fontSize="16px" fontWeight="semibold">
              Access URL:{" "}
              <Text as="span" color="gray.500" fontSize="16px">
                {accessLinkUrl !== "N/A" ? 
                    <Link
                      href={accessLinkUrl} target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#9f7aea" }}
                    >
                      {accessLinkUrl}
                    </Link>
                   : "N/A"
                }
                
              </Text>
            </Text>
            <Text color="gray.400" fontSize="16px" fontWeight="semibold">
              Start Date:{" "}
              <Text as="span" color="gray.500" fontSize="16px">
                {startDate.split("T")[0]}
              </Text>
            </Text>
            <Text color="gray.400" fontSize="16px" fontWeight="semibold">
              Status:{" "}
              <Text as="span" color={setStatusColor(status)} fontSize="16px"> 
                {status.toUpperCase()}
              </Text>
            </Text>
          </Flex>
          <Flex
            direction={{ sm: "column", md: "row" }}
            gap={2}
            style={{ justifyContent: "end", alignItems: "center", flexDirection: "column-reverse" }}
          >
            <Menu>
              <MenuButton
                as={Button}
                color="purple.600"
                variant="outline"
                w='30%'
                h='45'
                mb='24px'
                fontWeight='bold'
                justifyContent='center'
                _hover={{
                  bg: "purple.200",
                  color: 'white'
                }}
                _active={{
                  bg: "purple.400",
                  color: 'white'
                }}
                style={{ marginBottom: 0, width: "120px", height: "38px", borderRadius: "12px", border: "1px solid" }}
                // onClick={onOpen}
                isLoading={terminateloading || startloading}
                isDisabled={status === "pending" || status === "terminating" || status === "stopping"}
                rightIcon={<IoMdArrowDropdown />}
              >
                ACTIONS
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={OpenConnectModal}
                >
                  Connect
                </MenuItem>
                <MenuItem
                  onClick={status === "running" ? handleStop : handleStart}
                  isLoading={startloading}
                >
                  <Text fontSize="16px">
                    {status === "running" ? "Stop" : "Start"}
                  </Text>
                </MenuItem>
                <MenuItem
                  onClick={onOpen}
                  isLoading={terminateloading}
                >
                  Delete
                </MenuItem>


              </MenuList>
            </Menu>
          </Flex>
          {/* <Flex
            direction={{ sm: "column", md: "row" }}
            // align="flex-start"
            // p={{ md: "24px" }}
            gap={2}
            style={{ justifyContent: "end", alignItems: "center", flexDirection: "column-reverse" }}
          > */}
          {/* <Button
              p="0px"
              bg="transparent"
              mb={{ sm: "10px", md: "0px" }}
              me={{ md: "12px" }}
              // onClick={handleDelete}
              onClick={onOpen}
              isLoading={terminateloading}
              // isDisabled={terminateloading || startloading}
              isDisabled={status === "pending" || status === "terminating"}
            > */}
          {/* <Button colorScheme="purple"
              variant="outline"
              type='submit'
              fontSize='20px'
              w='30%'
              h='45'
              mb='24px'
              justifyContent='center'
              _hover={{
                bg: "purple.200",
                color: 'white'
              }}
              _active={{
                bg: "purple.400",
                color: 'white'
              }}
              style={{ marginBottom: 0, width: "90px", height: "38px", borderRadius: "12px" }}
              onClick={onOpen}
              isLoading={terminateloading}
              isDisabled={status === "pending" || status === "terminating" || status === "stopping"}

            >
              <Flex cursor="pointer" align="center" p="12px" > */}
          {/* <Icon as={FaTrashAlt} me="4px" /> */}
          {/* <Text fontWeight="semibold" fontSize="16px">
                  DELETE
                </Text>
              </Flex>
            </Button> */}

          {/* <Button
              p="0px"
              bg="transparent"
              onClick={status === "running" ? handleStop : handleStart}
              isLoading={startloading}
              // isDisabled={startloading || terminateloading}
              isDisabled={status === "pending" || status === "terminating"}
            > */}
          {/* <Button
              colorScheme="purple"
              variant="outline"
              type='submit'
              fontSize='20px'
              w='30%'
              h='45'
              mb='24px'
              justifyContent='center'
              _hover={{
                bg: "purple.200",
                color: 'white'
              }}
              _active={{
                bg: "purple.400",
                color: 'white'
              }}
              style={{ marginBottom: 0, width: "90px", height: "38px", borderRadius: "12px" }}
              isLoading={startloading}
              onClick={status === "running" ? handleStop : handleStart}
              isDisabled={status === "pending" || status === "stopping" || status === "terminating"}
            > */}
          {/* <Flex color={textColor} cursor="pointer" align="center" p="12px"> */}
          {/* <Flex cursor="pointer" align="center" p="12px" > */}
          { /* <Icon as={FaPencilAlt} me="4px" /> */}
          {/* <Text fontWeight="semibold" fontSize="16px">
                  {status === "running" ? "STOP" : "START"}
                </Text>
              </Flex> */}

          {/* </Button> */}


          {   /* 
          <Badge
            bg={status === "Running" ? "green.400" : bgStatus}
            color={status === "Stopped" ? "white" : colorStatus}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {status}
          </Badge> */ }


          {/* </Flex> */}
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <ModalBody>
            <Flex
              direction='column'
              w='800px'
              background='transparent'
              borderRadius='15px'
              p='40px'
              mx={{ base: "100px" }}
              bg={bgColor}
              boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
              <Text
                fontSize='xl'
                color={textColor}
                fontWeight='bold'
                textAlign='center'
                mb='22px'>
                Delete Lab
              </Text>
              <Text
                fontSize='s'
                color={textColor}
                fontWeight='bold'
                textAlign='center'
                mb='22px'>
                Are you sure you want to delete?
              </Text>
              <HStack spacing='15px' justify='center' mb='22px'>
              </HStack>

              <FormControl>
                <FormLabel ms='4px' fontSize='md' fontWeight='normal'>
                  Would you like to provide feedback about the lab?
                </FormLabel>
                <Textarea
                  fontSize='sm'
                  ms='4px'
                  borderRadius='15px'
                  type='text'
                  // placeholder='Insert Public Key'
                  mb='24px'
                  size='lg'
                // onChange={event => setPubKey(event.currentTarget.value)}
                />
                <Button colorScheme="purple"
                  variant="outline"
                  type='submit'
                  fontSize='16px'
                  float='right'
                  w='30%'
                  h='45'
                  mb='24px'
                  justifyContent='center'
                  _hover={{
                    bg: "purple.200",
                    color: 'white'
                  }}
                  _active={{
                    bg: "purple.400",
                    color: 'white'
                  }}
                  // onClick={handleSubmitUpdate}
                  onClick={handleDelete}
                  isLoading={loading}
                  style={{width: "auto"}}
                >
                  MARK AS COMPLETE & DELETE
                </Button>
                <Button colorScheme="purple"
                  variant="outline"
                  type='submit'
                  fontSize='16px'
                  w='30%'
                  h='45'
                  mb='24px'
                  justifyContent='center'
                  _hover={{
                    bg: "purple.200",
                    color: 'white'
                  }}
                  _active={{
                    bg: "purple.400",
                    color: 'white'
                  }}
                  onClick={onClose}
                >
                  CANCEL
                </Button>
              </FormControl>


            </Flex>
          </ModalBody>


        </ModalContent>
      </Modal>
      <Modal isOpen={connectModal} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <ModalBody>
            <Flex
              direction='column'
              w='800px'
              background='transparent'
              borderRadius='15px'
              p='40px'
              mx={{ base: "100px" }}
              bg={bgColor}
            >
              {/* boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)' */}
              <Text
                fontSize='xl'
                color={textColor}
                fontWeight='bold'
                textAlign='left'
                mb='22px'>
                Connect to Instance
              </Text>
              <Text
                fontSize='s'
                color={textColor}
                textAlign='left'
                mb='22px'>
                Please see the following options below to select your connection method
              </Text>


              <FormControl>
                {/* <FormLabel ms='4px' fontSize='lg' fontWeight='normal'>
                  Connecting via SSH
                </FormLabel> */}
                <Text
                  fontSize='s'
                  color={textColor}
                  fontWeight='bold'
                  textAlign='left'
                >
                  Connecting via SSH
                </Text>

                <Flex mb={2} p={2} style={{
                  border: "1px solid black",
                  borderRadius: "4px"
                }}>
                  <Text
                    fontSize='s'
                    color={textColor}
                    textAlign='left'
                  >
                    ssh -o ProxyCommand="ssh -i &lt;private-key-name&gt; -W %h:%p {eid}@nvibstsbxmg.mywizardaiops-labs.com -p 443" -i &lt;private-key-name&gt; {eid}@{privateDNS} -p 22
                  </Text>
                  <Button
                    style={{ backgroundColor: "unset" }}
                  >
                    <FaCopy
                      style={{ width: "40px" }}
                      onClick={() => { handleCopy1('sshComm') }}
                    />
                  </Button>
                </Flex>

                <br></br>

                <Text
                  fontSize='s'
                  color={textColor}
                  fontWeight='bold'
                  textAlign='left'
                >
                  Download SSH Config File
                </Text>

                <Button colorScheme="purple"
                  variant="outline"
                  type='submit'
                  fontSize='16px'
                  float='left'
                  w='30%'
                  h='45'
                  mb='24px'
                  justifyContent='center'
                  _hover={{
                    bg: "purple.200",
                    color: 'white'
                  }}
                  _active={{
                    bg: "purple.400",
                    color: 'white'
                  }}
                  onClick={handleDownloadConfig}
                  style={{ width: "auto" }}
                >
                  DOWNLOAD
                </Button>
                <Flex style={{
                  padding: '14px'
                }}>
                  <Tooltip zIndex={10000} label='Please update the values at the top of the downloaded config file' placement='bottom'>
                    <span>
                      <Icon as={BsInfoCircle} ></Icon>
                    </span>
                  </Tooltip>
                </Flex>
                <Flex style={{
                  // display: "flex",
                  // flexDirection: "row",
                  padding: "10px"

                }}>
                  <Link
                    style={{
                      padding: "0px",
                      left: "0px",
                      position: "absolute",
                      textDecoration: "underline",
                      color: "#7500c0"
                    }}
                    onClick={() =>
                      fetchPresignedUrl("ssh_instructions_training.pdf")
                    }>More Instructions</Link>
                  {/* <Button
                    color="purple.200"
                    target="_blank" fontSize='15px' fontWeight='normal' float='left'
                    style={{ backgroundColor: "white", padding: "0px", right: "120px", bottom: "-5px", textDecoration: "underline" }}
                    onClick={() =>
                      fetchPresignedUrl("ssh_instructions_training.pdf")
                    }
                  >
                    More Instructions
                  </Button> */}
                </Flex>
                <br></br>
                <Text
                  fontSize='s'
                  color={textColor}
                  fontWeight='bold'
                  textAlign='left'
                >
                  Connecting via Other SSH Clients
                </Text>
                <Flex>
                  <Button
                    style={{ backgroundColor: "unset", padding: "0px", top: "-5px" }}
                  >
                    <FaCopy
                      style={{ width: "20px" }}
                      onClick={() => { handleCopy1('proxyhostname') }}
                    />
                  </Button>
                  <h4>Proxy Host Name: </h4>
                  <h4
                    style={{
                      marginLeft: "10px"
                    }}
                  >
                    nvibstsbxmg.mywizardaiops-labs.com
                  </h4>
                </Flex>
                <Flex>
                  <Button
                    style={{ backgroundColor: "unset", padding: "0px", top: "-5px" }}
                  >
                    <FaCopy
                      style={{ width: "20px" }}
                      onClick={() => { handleCopy1('proxyhostport') }}
                    />
                  </Button>
                  <h4>Proxy Host Port: </h4>
                  <h4
                    style={{
                      marginLeft: "10px"
                    }}
                  >
                    443
                  </h4>
                </Flex>
                <Flex>
                  <Button
                    style={{ backgroundColor: "unset", padding: "0px", top: "-5px" }}
                  >
                    <FaCopy
                      style={{ width: "20px" }}
                      onClick={() => { handleCopy1('hostname') }}
                    />
                  </Button>
                  <h4>Host Name: </h4>
                  <h4
                    style={{
                      marginLeft: "10px"
                    }}
                  >
                    {privateDNS}
                  </h4>
                </Flex>
                <Flex>
                  <Button
                    style={{ backgroundColor: "unset", padding: "0px", top: "-5px" }}
                  >
                    <FaCopy
                      style={{ width: "20px" }}
                      onClick={() => { handleCopy1('hostport') }}
                    />
                  </Button>
                  <h4>Host Port: </h4>
                  <h4
                    style={{
                      marginLeft: "10px"
                    }}
                  >
                    22
                  </h4>
                </Flex>
                <Flex>
                  <Button
                    style={{ backgroundColor: "unset", padding: "0px", top: "-5px" }}
                  >
                    <FaCopy
                      style={{ width: "20px" }}
                      onClick={() => { handleCopy1('user') }}
                    />
                  </Button>
                  <h4>User: </h4>
                  <h4
                    style={{
                      marginLeft: "10px"
                    }}
                  >
                    {eid}
                  </h4>
                </Flex>
                <Button colorScheme="purple"
                  variant="outline"
                  type='submit'
                  fontSize='16px'
                  float='right'
                  w='30%'
                  h='45'
                  mb='24px'
                  justifyContent='center'
                  _hover={{
                    bg: "purple.200",
                    color: 'white'
                  }}
                  _active={{
                    bg: "purple.400",
                    color: 'white'
                  }}
                  onClick={handleClose}
                  style={{ width: "auto" }}
                >
                  CLOSE
                </Button>
              </FormControl>
            </Flex>
          </ModalBody>


        </ModalContent>
      </Modal >
    </div >
  );
}

export default BillingRow;
