import update from "immutability-helper";
import { headers } from "helper/api";

import axios from "axios";

//Action
export const START_LOADING = "dashboard/START_LOADING";

export const FETCH_ACTIVE_SESSIONS_DATA = "dashboard/FETCH_ACTIVE_SESSIONS_DATA";

export const ASYNC_ERROR = "dashboard/ASYNC_ERROR";




//URLs
const fetchActiveSessionsDataUrl = `https://api-cam-training.mywizard-aiops.com`;


//Reducer
const initState = {
  

  activeSessionsLoading: true,

  activeSessions: [],

  isLoading: false,

  error: {},
  
};

export default function reducer(state = initState, action) {
    
  const data = action.data;
  switch (action.type) {
    case START_LOADING:
      return update(state, { isLoading: { $set: true } });
    case FETCH_ACTIVE_SESSIONS_DATA:
      if (JSON.stringify(state.activeSessions) !== JSON.stringify(data)) {
        return {
          ...state,
          activeSessions: data,
          isLoading: false,
        };
      }
      return { ...state };

    
    case ASYNC_ERROR:
      if (action.type === "dashboard/ASYNC_ERROR") {
        return update(state, {
          error: { $set: data },
          // isLoading: { $set: false }
        });
      }
      return { ...state };
   
    default:
      return state;
  }
}

//Action Creator 

export const fetchActiveSessionsData = () => async (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  dispatch({ type: START_LOADING });

  if (idToken) {
    const urls = fetchActiveSessionsDataUrl + "/ec2/ec2instances";

    try {
          const res = await axios
              .get(urls, {
                  headers: { ...headers, Authorization: idToken },
                  params: {
                      inititive: activeAccount
                  }
              });
          dispatch({ type: FETCH_ACTIVE_SESSIONS_DATA, data: res.data });
      } catch (err) {
          dispatch({
              type: ASYNC_ERROR,
              data: err
          });
      }
  }
};

