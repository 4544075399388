/*eslint-disable*/
// chakra imports
import {
  Box,
  Button, Container, Flex,
  Link,
  Stack,
  Text,
  useColorModeValue,
  Grid,
  GridItem,
  Image
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { SupportIcon } from "components/Icons/Icons";
import { CreativeTimLogo } from "components/Icons/Icons";
import { Separator } from "components/Separator/Separator";
// import { SidebarHelp } from "components/Sidebar/SidebarHelp";
import React from "react";
import { NavLink, Redirect, useLocation } from "react-router-dom";
import Logo from "assets/img/aiops/logo.png"

// this function creates the links and collapses that appear in the sidebar (left menu)


const SidebarContent = ({ logoText, routes }) => {

  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  const createLinks = (routes) => {
    // Chakra Color Mode
    const activeBg = useColorModeValue("white", "purple.700");
    const inactiveBg = useColorModeValue("white", "purple.700");
    const activeColor = useColorModeValue("purple.700", "white");
    const inactiveColor = useColorModeValue("purple.400", "purple.400");

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.category) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <div key={prop.name}>
            <Text
              color={activeColor}
              fontWeight="bold"
              mb={{
                xl: "12px",
              }}
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
            >
              {document.documentElement.dir === "rtl"
                ? prop.rtlName
                : prop.name}
            </Text>
            {createLinks(prop.views)}
          </div>
        );
      }

      return (

        <NavLink to={prop.layout + prop.path} key={prop.name}>
          {activeRoute(prop.layout + prop.path) === "active" ? (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg={activeBg}
              mb={{
                xl: "12px",
              }}
              mx={{
                xl: "auto",
              }}
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
              borderRadius="15px"
              _hover="none"
              w="100%"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              style={{display: prop.name === "Dashboard" ? "none": null}}
            >
              <Flex>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg="purple.300"
                    color="white"
                    h="30px"
                    w="30px"
                    me="12px"
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={activeColor} my="auto" fontSize="17px">
                  {document.documentElement.dir === "rtl"
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg="transparent"
              mb={{
                xl: "12px",
              }}
              mx={{
                xl: "auto",
              }}
              py="12px"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              borderRadius="15px"
              _hover="none"
              w="100%"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              style={{display: prop.name === "Dashboard" ? "none": null}}
            >
              <Flex>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg={inactiveBg}
                    color="purple.300"
                    h="30px"
                    w="30px"
                    me="12px"
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={inactiveColor} my="auto" fontSize="17px">
                  {document.documentElement.dir === "rtl"
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>

      );
    });
  };

  const links = <>{createLinks(routes)}</>;

  return (
    <>
      <Box pt={"20px"} mb="12px">
        <Link
          href={`${process.env.PUBLIC_URL}/#/admin/dashboard`}
          // target="_blank"
          display="flex"
          lineHeight="100%"
          mb="30px"
          fontWeight="bold"
          justifyContent="center"
          alignItems="center"
          fontSize="11px"
        >
          <Image
              src={Logo}
              alt='chakra image'
              style={{width:"18%", paddingRight:"3%"}}
            />
          <Text fontSize="lg" mt="3px">
            {logoText}
          </Text>
        </Link>
        <Separator></Separator>
      </Box>
      <Stack direction="column" mb="40px">
        <Box>{links}</Box>
        <Box p='6' style={{marginTop:"-1.5%"}}>
          <Grid templateColumns='repeat(5, 1fr)'>
            <GridItem colStart={1}>
            <div style={{height:"30px", width:"30px", background:"white",borderRadius:"12px",textAlign:"center",marginLeft:"-10px"}}>
              <SupportIcon
                color="purple.300"
              />
            </div>
            </GridItem>
            <GridItem>
              <Link
                target={"_blank"}
                href="https://mywizard-aiops.accenture.com/platform-support/"
                w="100%"
                mb="5px"
                style={{
                  color: "#9F7AEA",
                  marginTop: "auto",
                  marginBottom: "auto",
                  fontSize: "17px",
                  fontWeight: "bold",
                  padding: "7px"
                }}
              >Support
              </Link>
            </GridItem>
          </Grid>
        </Box>

      </Stack>
      {/*  <SidebarHelp /> */}
    </>
  )
}

export default SidebarContent