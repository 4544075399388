// Chakra imports
import { Box, Flex, Grid, Icon, CircularProgress } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Authors from "./components/Authors";
import { BellIcon, ViewIcon } from '@chakra-ui/icons'
import NewLab from "./components/NewLab";
import { tablesTableData, dashboardTableData } from "variables/general";
import PaymentStatistics from "./components/PaymentStatistics";
import { connect } from "react-redux";
import Axios from "axios";
import SessionsInformation from "../Dashboard/components/SessionsInformation";
import { tokenValidation } from "helper/customizedHooks";
import LabHistory from "./components/LabHistory";

const Tables = ({
  component: Component,
  getUserDetails,
  saveTokens,
  checkIsRegistration,
  exp,
  email,
  isAuthenticated,
  loadingAuthentication,
  ...rest
}) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeSessions, setActiveSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true)



  const idToken = localStorage.getItem("token");


  const baseURL = "https://api-cam-training.mywizard-aiops.com";

  useEffect(() => {
    setInterval(() => {
      tokenValidation();
    }, 4000);
  }, []);

  useEffect(() => {
    let timer;
    if (idToken) {
      getOrders();
      timer = setInterval(() => {
        const apiCall = () => {
          getOrders();
          getActiveSessions();
        };
        apiCall();
      }, 4000);
      return () => clearInterval(timer);
    }

  }, [idToken]);

  const getOrders = async () => {
    const urls = baseURL + "/fui/orders?inititive=na";
    Axios.get(urls, options).then((response) => {
      const input = response.data
      let sortedInput = input.filter(item => item.Status.startsWith("Term"))
      // let sortedInput = input.reverse()
      setOrders(sortedInput);
      setLoading(false)
    }).catch((err) => {
      //toast.error("ERROR! Please try again after sometime.");
      console.log("Async error", err);
    });
  };

  const getActiveSessions = async () => {
    const urls = baseURL + "/ec2/ec2instances?inititive=na";
    Axios.get(urls, options).then((response) => {
      setActiveSessions(response.data);
      setIsLoading(false)
      // console.log(response.data)

    }).catch((err) => {
      //toast.error("ERROR! Please try again after sometime.");
      console.log("Async error", err);
    });
  };

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  const sortedOrdersData = orders ? orders.sort((a, b) => new Date(b.CreateDate.split("T")[0]) - new Date(a.CreateDate.split("T")[0])) : undefined;
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ sm: "1fr" }} templateRows='1fr'>
        <Box>
          <Grid
            templateColumns={{
              sm: "1fr",
              md: "1fr 1fr",
              xl: "1fr 1fr 1fr 1fr 1fr 1fr",
            }}
            templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
            gap='26px'>

            <NewLab />
          </Grid>
        </Box>
        { /*  <Invoices title={"Invoices"} data={invoicesData} /> */}
      </Grid>
      <Grid>
        <br></br>
        <SessionsInformation title={"Active Lab Sessions"} data={activeSessions ? activeSessions : undefined} isLoading={isLoading} />
      </Grid>
      <Grid>
        <Authors
          title={"Lab History"}
          captions={["Session Name", "Lab", "Status", "Started"]}
          data={sortedOrdersData ? sortedOrdersData : undefined}
          Loading={loading}
        />
        {/* <LabHistory title={"Lab Histoy"} data={sortedOrdersData ? sortedOrdersData : undefined} isLoading={isLoading} /> */}
      </Grid>
      <br></br><br></br>
    </Flex>
  );
}
const MSTP = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken
});

export default connect(MSTP)(Tables);
