import React from "react";
import { CircularProgress, Text } from "@chakra-ui/react";
import BillingRow from "components/Tables/BillingRow";


const ServerDataList = ({ serverData, loading }) => {  
  return (
    <div className="approval_data_list">
      {(() => {
        if (!loading) {
          if (serverData.length == 0) {
            return (<p style={{ marginTop: "1%" }}>No Lab Sessions</p>)
          } else {
            return (
              serverData.map((row) => {
                return (
                  <BillingRow
                    inititive={row.Initiative}
                    accountId={row.accountId}
                    accountType={row.accountType}
                    instanceId={row.instanceId}
                    productType={row.platformType}
                    clusterId={row.clusterId}
                    accessLinkUrl={row.accessLinkUrl}
                    // startDate={row.camStartedDate}
                    startDate={row.createddate}
                    status={row.instanceState}
                    privateDNS={row.privateDNSName}
                  />
                );
              })

            )
          }
        }
      })()}
    </div>
  )
}

export default ServerDataList;
