// Chakra imports
import { ChakraProvider, Portal, useDisclosure, Image, Text, Box, Link } from '@chakra-ui/react';
import Configurator from 'components/Configurator/Configurator';
import Footer from 'components/Footer/Footer.js';
// Layout components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes.js';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// Custom Chakra theme
import theme from 'theme/theme.js';
import FixedPlugin from '../components/FixedPlugin/FixedPlugin';
// Custom components
import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';
import { connect, useSelector } from 'react-redux';
import { getUserDetails, saveTokens } from 'redux/auth/authActions';
import Logo from "assets/img/aiops/logo.png"


const Admin = (props) => {
	
  const authDetails = useSelector((state)=>state.auth);
  const isAuthenticated = authDetails.isAuthenticated
  localStorage.setItem("isAuthenticated", isAuthenticated);
  const email = authDetails.userDetails.email;
  localStorage.setItem("email", email);
  const exp = authDetails.userDetails.exp;
  localStorage.setItem("exp", exp);
  const eid = email ? email.split("@")[0] : "";
  localStorage.setItem("eid", eid);

  
  
	const { ...rest } = props;
	// states and functions
	const [ sidebarVariant, setSidebarVariant ] = useState('transparent');
	const [ fixed, setFixed ] = useState(false);
	// functions for changing the states from components
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].views);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	// This changes navbar state(fixed or not)
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].views);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					if (routes[i].secondaryNavbar) {
						return routes[i].secondaryNavbar;
					}
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.category === 'account') {
				return getRoutes(prop.views);
			}
			if (prop.layout === '/admin') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			} else {
				return null;
			}
		});
	};
	const { isOpen, onOpen, onClose } = useDisclosure();
	document.documentElement.dir = 'ltr';
	// Chakra Color Mode
	return (
		<ChakraProvider theme={theme} resetCss={false}>
			{/* <div style={{background:"#8F3DD5", padding:"17px", paddingLeft:"3%", position: "fixed",top: "0", zIndex:"999", width:"-webkit-fill-available", color:"white"}} >
			<Link
			target="_blank"
			display="flex"
			fontWeight="bold"
			>
			<Image
				src={Logo}
				alt='chakra image'
				style={{width:"2%", paddingRight:"10px"}}
				/>
			<Text fontSize="lg">
						<span>AiOps Training center</span>
						
					</Text>
			</Link>
				
			</div> */}
			<Sidebar
				routes={routes}
				logoText={'AiOps Training Labs'}
				display='none'
				sidebarVariant={sidebarVariant}
				// style={{marginTop:"10%"}}
				{...rest}
			/>
			<MainPanel
				w={{
					base: '100%',
					xl: 'calc(100% - 275px)'
				}}>
				<Portal>
					<AdminNavbar
						onOpen={onOpen}
						logoText={'AiOps Training Labs'}
						brandText={getActiveRoute(routes)}
						secondary={getActiveNavbar(routes)}
						fixed={fixed}
						{...rest}
					/>
				</Portal>
				{getRoute() ? (
					<PanelContent>
						<PanelContainer>
							<Switch>
								{getRoutes(routes)}
								<Redirect from='/admin' to='/admin/dashboard' />
							</Switch>
						</PanelContainer>
					</PanelContent>
				) : null}
				<Footer />
				<Portal>
					<FixedPlugin secondary={getActiveNavbar(routes)} fixed={fixed} onOpen={onOpen} />
				</Portal>
				<Configurator
					secondary={getActiveNavbar(routes)}
					isOpen={isOpen}
					onClose={onClose}
					isChecked={fixed}
					onSwitch={(value) => {
						setFixed(value);
					}}
					onOpaque={() => setSidebarVariant('opaque')}
					onTransparent={() => setSidebarVariant('transparent')}
				/>
			</MainPanel>
		</ChakraProvider>
	);
}
const mapStateToProps = state => {
	return {
	  exp: state.auth.userDetails.exp,
	  email: state.auth.userDetails.email,
	  loadingAuthentication: state.auth.loadingAuthentication,
	  isAuthenticated: state.auth.isAuthenticated
	};
  };
  const mapDispatchToProps = dispatch => ({
	getUserDetails: idToken => dispatch(getUserDetails(idToken)),
	saveTokens: tokenObj => dispatch(saveTokens(tokenObj)),
  });
  
  export default connect(
	mapStateToProps,
	mapDispatchToProps
  )(Admin);