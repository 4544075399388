// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar7 from "assets/img/avatars/avatar7.png";
import avatar8 from "assets/img/avatars/avatar8.png";
import avatar9 from "assets/img/avatars/avatar9.png";
import avatar10 from "assets/img/avatars/avatar10.png";
import ATR150 from "assets/img/aiops/ATR150.png";
import peopleimage from "assets/img/aiops/stack.png";

// Custom icons
import {
  AdobexdLogo,
  AtlassianLogo,
  InvisionLogo,
  JiraLogo,
  SlackLogo,
  SpotifyLogo,
} from "components/Icons/Icons.js";
import { AiOutlineExclamation } from "react-icons/ai";
import {
  FaArrowDown,
  FaArrowUp,
  FaBell,
  FaCreditCard,
  FaFilePdf,
  FaHtml5,
  FaShoppingCart,
} from "react-icons/fa";
import { SiDropbox } from "react-icons/si";

export const dashboardTableData = [
  {
    logo: AdobexdLogo,
    name: "Purity UI Version",
    members: [avatar1, avatar2, avatar3, avatar4, avatar5],
    budget: "$14,000",
    progression: 60,
  },
  {
    logo: AtlassianLogo,
    name: "Add Progress Track",
    members: [avatar3, avatar2],
    budget: "$3,000",
    progression: 10,
  },
  {
    logo: SlackLogo,
    name: "Fix Platform Errors",
    members: [avatar10, avatar4],
    budget: "Not set",
    progression: 100,
  },
  {
    logo: SpotifyLogo,
    name: "Launch our Mobile App",
    members: [avatar2, avatar3, avatar7, avatar8],
    budget: "$32,000",
    progression: 100,
  },
  {
    logo: JiraLogo,
    name: "Add the New Pricing Page",
    members: [avatar10, avatar3, avatar7, avatar2, avatar8],
    budget: "$400",
    progression: 25,
  },
  {
    logo: InvisionLogo,
    name: "Redesign New Online Shop",
    members: [avatar9, avatar3, avatar2],
    budget: "$7,600",
    progression: 40,
  },
];

export const timelineData = [
  {
    logo: "1",
    title: "Key Registration",
    date: "Click on 'Register' Button. Input public key and submit.",
    color: "purple.300",
  },
  {
    logo: "2",
    title: "Request Server Access",
    date: "Input Private DNS (You can find the private DNS from the lab owner) and submit",
    color: "orange",
  },
  {
    logo: "3",
    title: "Request Approval",
    date: "You can find the submitted requests in the ‘Requested Approvals’ section, where status of that request is shown.",
    color: "blue.400",
  },
  {
    logo: "4",
    title: "Approve/Decline Request",
    date: "Submitted request will be shown in ‘Pending Approvals’ section in Lab Owner dashboard. Lab Owner can approve/decline the request. Once approved, requester will get access to the lab.",
    color: "orange.300",
  },
  {
    logo: "5",
    title: "VPN Connection",
    date: "Connect to the VPN. If you don’t have VPN access, refer to “How do I raise request for VPN access?” in the FAQ Page.",
    color: "purple",
  },
  {
    logo: "6",
    title: "Accessing the Lab",
    date: "You can access the lab by following the steps in “How can I access the server?” in the FAQ page.",
  },
];
export const rtlDashboardTableData = [
  {
    logo: AdobexdLogo,
    name: "نسخة Purity UI",
    members: [avatar1, avatar2, avatar3, avatar4, avatar5],
    budget: "$14,000",
    progression: 60,
  },
  {
    logo: AtlassianLogo,
    name: "إضافة مسار التقدم",
    members: [avatar3, avatar2],
    budget: "$3,000",
    progression: 10,
  },
  {
    logo: SlackLogo,
    name: "إصلاح أخطاء النظام الأساسي",
    members: [avatar10, avatar4],
    budget: "غير مضبوط",
    progression: 100,
  },
  {
    logo: SpotifyLogo,
    name: "إطلاق تطبيق الهاتف المحمول الخاص بنا",
    members: [avatar2, avatar3, avatar7, avatar8],
    budget: "$32,000",
    progression: 100,
  },
  {
    logo: JiraLogo,
    name: "أضف صفحة التسعير الجديدة",
    members: [avatar10, avatar3, avatar7, avatar2, avatar8],
    budget: "$400",
    progression: 25,
  },
  {
    logo: InvisionLogo,
    name: "إعادة تصميم متجر جديد على الإنترنت",
    members: [avatar9, avatar3, avatar2],
    budget: "$7,600",
    progression: 40,
  },
];

export const rtlTimelineData = [
  {
    logo: FaBell,
    title: "$2400, تغييرات في التصميم",
    date: "22 DEC 7:20 PM",
    color: "purple.300",
  },
  {
    logo: FaHtml5,
    title: "طلب جديد #4219423",
    date: "21 DEC 11:21 PM",
    color: "orange",
  },
  {
    logo: FaShoppingCart,
    title: "مدفوعات الخادم لشهر أبريل",
    date: "21 DEC 9:28 PM",
    color: "blue.400",
  },
  {
    logo: FaCreditCard,
    title: "تمت إضافة بطاقة جديدة للطلب #3210145",
    date: "20 DEC 3:52 PM",
    color: "orange.300",
  },
  {
    logo: SiDropbox,
    title: "فتح الحزم من أجل التنمية",
    date: "19 DEC 11:35 PM",
    color: "purple",
  },
  {
    logo: AdobexdLogo,
    title: "طلب جديد #9851258",
    date: "18 DEC 4:41 PM",
  },
];

export const tablesTableData = [
  {
    logo: ATR150,
    name: "ATR Training 01",
    email: "https://atrtraining01.aiops-bootcamp.com",
    subdomain: "Module 1",
    domain: "myWizard Aiops ATR",
    status: "Running",
    date: "24/11/22",
  },
  {
    logo: peopleimage,
    name: "Splunk Training 01",
    email: "https://dh1.aiops-bootcamp.com/splunktest01",
    subdomain: "Module 1",
    domain: "myWizard Aiops Stack 360",
    status: "Stopped",
    date: "24/11/22",
  },
  {
    logo: peopleimage,
    name: "Splunk Training 02",
    email: "https://dh1.aiops-bootcamp.com/splunktest02",
    subdomain: "Module 1",
    domain: "myWizard Aiops Splunk Platform",
    status: "Stopped",
    date: "24/11/22",
  },
  {
    logo: peopleimage,
    name: "Splunk Training 03",
    email: "https://dh1.aiops-bootcamp.com/splunktest02",
    subdomain: "Module 1",
    domain: "myWizard Aiops Splunk Platform",
    status: "Completed",
    date: "24/11/22",
  },
  {
    logo: ATR150,
    name: "ATR Training 02",
    email: "https://dh1.aiops-bootcamp.com/splunktest02",
    subdomain: "Module 1",
    domain: "myWizard Aiops Splunk Platform",
    status: "Completed",
    date: "24/11/22",
  },
  {
    logo: peopleimage,
    name: "Splunk Training 04",
    email: "https://dh1.aiops-bootcamp.com/splunktest02",
    subdomain: "Module 1",
    domain: "myWizard Aiops Splunk Platform",
    status: "Cancelled",
    date: "24/11/22",
  },
];

export const tablesProjectData = [
  {
    logo: AdobexdLogo,
    name: "Purity UI Version",
    budget: "$14,000",
    status: "Working",
    progression: 60,
  },
  {
    logo: AtlassianLogo,
    name: "Add Progress Track",
    budget: "$3,000",
    status: "Canceled",
    progression: 10,
  },
  {
    logo: SlackLogo,
    name: "Fix Platform Errors",
    budget: "Not set",
    status: "Done",
    progression: 100,
  },
  {
    logo: SpotifyLogo,
    name: "Launch our Mobile App",
    budget: "$32,000",
    status: "Done",
    progression: 100,
  },
  {
    logo: JiraLogo,
    name: "Add the New Pricing Page",
    budget: "$400",
    status: "Working",
    progression: 25,
  },
];

export const invoicesData = [
  {
    date: "March, 01, 2020",
    code: "#MS-415646",
    price: "$180",
    logo: FaFilePdf,
    format: "PDF",
  },
  {
    date: "February, 10, 2020",
    code: "#RV-126749",
    price: "$250",
    logo: FaFilePdf,
    format: "PDF",
  },
  {
    date: "April, 05, 2020",
    code: "#FB-212562",
    price: "$560",
    logo: FaFilePdf,
    format: "PDF",
  },
  {
    date: "June, 25, 2019",
    code: "#QW-103578",
    price: "$120",
    logo: FaFilePdf,
    format: "PDF",
  },
  {
    date: "March, 01, 2019",
    code: "#AR-803481",
    price: "$300",
    logo: FaFilePdf,
    format: "PDF",
  },
];

export const billingData = [
  {
    name: "ATR Training 01",
    company: "myWizard Aiops ATR",
    email: "https://atrtraining01.aiops-bootcamp.com",
    number: "24/11/2022",
    status: "Running"
  },
  {
    name: "Splunk Training 01",
    company: "myWizard Aiops Stack 360",
    email: "https://dh1.aiops-bootcamp.com/splunktest01",
    number: "24/11/2022",
    status: "Stopped"
  },
  {
    name: "Splunk Training 02",
    company: "myWizard Aiops Splunk",
    email: "https://dh1.aiops-bootcamp.com/splunktest02",
    number: "24/11/2022",
    status: "Stopped"
  },
];

export const newestTransactions = [
  {
    name: "Netflix",
    date: "27 March 2021, at 12:30 PM",
    price: "- $2,500",
    logo: FaArrowDown,
  },
  {
    name: "Apple",
    date: "27 March 2021, at 12:30 PM",
    price: "+ $2,500",
    logo: FaArrowUp,
  },
];

export const olderTransactions = [
  {
    name: "Stripe",
    date: "26 March 2021, at 13:45 PM",
    price: "+ $800",
    logo: FaArrowUp,
  },
  {
    name: "HubSpot",
    date: "26 March 2021, at 12:30 PM",
    price: "+ $1,700",
    logo: FaArrowUp,
  },
  {
    name: "Webflow",
    date: "26 March 2021, at 05:00 PM",
    price: "Pending",
    logo: AiOutlineExclamation,
  },
  {
    name: "Microsoft",
    date: "25 March 2021, at 16:30 PM",
    price: "- $987",
    logo: FaArrowDown,
  },
];
