import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaPencilAlt, FaTrashAlt, FaCheck } from "react-icons/fa";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'

const ApprovalsData = ({request_id, private_dns, instance_owner, requested_on, status}) => {
    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("#F8F9FA", "gray.800");
    const nameColor = useColorModeValue("gray.500", "white");
    
    const setStatusColor = (status) => {
        switch (status) {
          case "approved":
            return "green.400";
          case "declined":
            return "red.400";
          case "pending":
            return "#ebcc34";
          case "in-progress":
            return "#4B9CD3";
    
          default:
            return "gray.500";
        }
      }
    



    return (
        <Box p="24px" bg={bgColor} my="22px" borderRadius="12px">
            <Flex justify="space-between" w="100%">
                <Flex direction="column" maxWidth="70%">
                    <Text color="gray.400" fontSize="16px" fontWeight="semibold">
                        Request ID:{" "}
                        <Text as="span" color="gray.500">
                            {request_id}
                        </Text>
                    </Text>
                    <Text color="gray.400" fontSize="16px" fontWeight="semibold">
                        Private DNS:{" "}
                        <Text as="span" color="gray.500">
                            {private_dns}
                        </Text>
                    </Text>
                    <Text color="gray.400" fontSize="16px" fontWeight="semibold">
                        Instance Owner:{" "}
                        <Text as="span" color="gray.500">
                            {instance_owner}
                        </Text>
                    </Text>
                    <Text color="gray.400" fontSize="16px" fontWeight="semibold">
                        Requested On:{" "}
                        <Text as="span" color="gray.500">
                            {requested_on}
                        </Text>
                    </Text>
                    <Text color="gray.400" fontSize="16px" fontWeight="semibold" >
                        Status:{" "}
                        <Text as="span" color={setStatusColor(status)} style={{textTransform: 'capitalize'}}>
                            {status}
                        </Text>
                    </Text>
                </Flex>
            </Flex>
        </Box>
    );
}

export default ApprovalsData;
