import {
    Box,
    Flex,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";


const ApprovalsDataPending = (props) => {
    const bgColor = useColorModeValue("#F8F9FA", "gray.800");
    const { request_id, private_dns, requestor, requested_on, approvalButton, declineButton } = props;

    return (
        <Box p="24px" bg={bgColor} my="22px" borderRadius="12px">
            <Flex justify="space-between" w="100%">
                <Flex direction="column" maxWidth="70%">
                    <Text color="gray.400" fontSize="16px" fontWeight="semibold">
                        Request ID:{" "}
                        <Text as="span" color="gray.500">
                            {request_id}
                        </Text>
                    </Text>
                    <Text color="gray.400" fontSize="16px" fontWeight="semibold">
                        Private DNS:{" "}
                        <Text as="span" color="gray.500">
                            {private_dns}
                        </Text>
                    </Text>
                    <Text color="gray.400" fontSize="16px" fontWeight="semibold">
                        Requester:{" "}
                        <Text as="span" color="gray.500">
                            {requestor}
                        </Text>
                    </Text>
                    <Text color="gray.400" fontSize="16px" fontWeight="semibold">
                        Requested On:{" "}
                        <Text as="span" color="gray.500">
                            {requested_on}
                        </Text>
                    </Text>
                </Flex>
                <Flex
                    direction={{ sm: "column", md: "row" }}
                    // align="flex-start"
                    // p={{ md: "24px" }}
                    gap={2}
                    style={{justifyContent: "end", alignItems: "center", flexDirection: "column-reverse"}}
                >
                    {declineButton}
                    {approvalButton}
                </Flex>
            </Flex>
        </Box>
    );
}

export default ApprovalsDataPending;
