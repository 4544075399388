// Chakra imports
import { Center, CircularProgress, Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BillingRow from "components/Tables/BillingRow";
import ServersList from "./ServersList";
import React, { useState } from "react";
import Pagination from "views/Dashboard/Billing/components/Pagination";



const SessionsInformation = ({ title, data, isLoading }) => {
  const textColor = useColorModeValue("purple.700", "white");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(3);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;

  const sortedServerData = data ? data.sort((a, b) => new Date(b.createddate.split("T")[0]) - new Date(a.createddate.split("T")[0])) : undefined;

  return (
    <Card my={{ lg: "34px" }} me={{ lg: "24px" }}>
      <Flex direction='column'>
        <CardHeader py='12px'>
          <Text color={textColor} fontSize='lg' fontWeight='bold'>
            {title}
          </Text>
        </CardHeader>
        <CardBody mt='-3'>
          <Flex direction='column' w='100%'>
            {!isLoading ? " " : <div><br></br><CircularProgress isIndeterminate color='purple.300' /></div>}
            <ServersList serverData={data ? data.slice(firstPostIndex, lastPostIndex) : undefined} loading={isLoading} />
            {data ?
              data.length > 0 ? <Pagination
                totalPosts={data ? data.length : 1}
                postsPerPage={postsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              /> : " "
              : " "}
          </Flex>
        </CardBody>
      </Flex>
    </Card>
  );
};

export default SessionsInformation;
