// Chakra imports
import {
  Flex,
  Grid,
  Image,
  SimpleGrid,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text, Link, Button, InputGroup, Input, InputRightElement, CircularProgress
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import { Separator } from "components/Separator/Separator";
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon
} from "components/Icons/Icons.js";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import OrdersOverview from "../Dashboard/components/OrdersOverview";
import { timelineData } from "variables/general";

const Bootcamps = () => {

  const baseURL = "https://api-cam-training.mywizard-aiops.com";
  const eid = localStorage.getItem("eid");
  const [bootcampDetails, setBootcampDetails] = useState([]);
  const idToken = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timer;
    if (idToken) {
      getBootcampDetails();
      timer = setInterval(() => {
        const apiCall = () => {
          getBootcampDetails();
        };
        apiCall();
      }, 10000);
      return () => clearInterval(timer);
    }
  }, [idToken]);

  const getBootcampDetails = async () => {
    const urls = baseURL + "/bootcamp-details";
    Axios.get(urls, options).then((response) => {
      setBootcampDetails(response.data);
      setLoading(false)
    }).catch((err) => {
      //toast.error("ERROR! Please try again after sometime.");
      console.log("Async error", err);
    });
  };

  const fetchPresignedUrl = (fileName) => {
    const idToken = localStorage.getItem("token");
    const urls = baseURL + "/faq";
    if (idToken) {
      Axios
        .get(
          urls +
          `/${fileName}?customer_enterprise_id=${eid}&inititive=na`,
          {
            headers: { Authorization: idToken },
          }
        )
        .then((res) => {
          window.open(res.data[0], "_blank");
        })
        .catch((err) => {

        });
    }
  };

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  const [show1, setShow1] = React.useState(false)
  const [show2, setShow2] = React.useState(false)
  const [show3, setShow3] = React.useState(false)
  const [show4, setShow4] = React.useState(false)
  const [show5, setShow5] = React.useState(false)
  const [show6, setShow6] = React.useState(false)
  const [show7, setShow7] = React.useState(false)



  const handleClick = (e) => {
    const value = e.target.value
    if (value == '1') {
      setShow1(!show1)
    } else if (value == '2') {
      setShow2(!show2)
    } else if (value == '3') {
      setShow3(!show3)
    } else if (value == '4') {
      setShow4(!show4)
    } else if (value == '5') {
      setShow5(!show5)
    } else if (value == '6') {
      setShow6(!show6)
    } else if (value == '7') {
      setShow7(!show7)
    }
  }

  return (
    <div>
      <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
          templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
          gap='24px'
          mb='25px'
        >
          {loading ? (
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop:"5%"
        }}>
          <CircularProgress isIndeterminate color='#7500c0' />
        </div>
      ) : (
            bootcampDetails.length == 0 ?
              <Card maxH='100%' style={{ border: "2px solid #F8F9FA", background: "#F8F9FA" }} mb="15px">
                <CardHeader p='5px 0px 5px 14px' style={{ background: "#F8F9FA" }}>
                  <Flex direction='column'>
                    <Text color="gray.600" fontSize='lg' fontWeight='bold' pb='.5rem'>
                    Oops! It looks like you're not currently registered for any bootcamps&#128542;. 
                    </Text>
                    <Text color="gray.600" fontSize='lg' fontWeight='bold' pb='.5rem'>
                    If you've already registered and can't find your details here, please let the bootcamp coordinator know or reach out to one of the trainers for assistance.
                    </Text>
                  </Flex>
                </CardHeader>
              </Card> :
              bootcampDetails.map((row) => {
                return (
                  <div style={{ background: "white", borderRadius: "15px" }} className="bootcamps">
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2 >
                          <AccordionButton>
                            <Flex align="center" gap={10} pt={{ base: "150px", md: "75px" }} mb='24px' w="100%">
                              <div>
                                <div style={{ background: "#7500c0", color: "white", borderRadius: "8px", marginBottom: "15px", width: "60%", marginLeft: "20%" }}>
                                  <Separator style={{ height: "4px" }} />
                                  <Text fontSize='lg' fontWeight='bold' mt='10px'>
                                    {row.bootcamp_name}
                                  </Text>
                                  <Text
                                    fontSize='xs'
                                    // color='purple.400'
                                    fontWeight='normal'>
                                    Date: {row.date}
                                  </Text>
                                  <Text
                                    mb='15px'
                                    fontSize='xs'
                                    // color='purple.400'
                                    fontWeight='bold'>
                                    Status: {row.status.toUpperCase()}
                                  </Text>
                                  <Separator style={{ height: "3px" }} />
                                </div>
                                <Text fontSize='md' ><b>Group name:</b> {row.lab_group_name}</Text>
                                <Text fontSize='md'><b>Quasar++ DNS:</b> {row.quasar_ip_address}</Text>
                                <Text fontSize='md'><b>ATR URL:</b> <a href={row.atr_url} target="_blank">{row.atr_url}</a></Text>
                              </div>
                            </Flex>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} mt={4}>
                          <Card maxH='100%' style={{ border: "2px solid #F8F9FA", background: "#F8F9FA" }} mb="15px">
                            <CardHeader p='5px 0px 5px 14px' style={{ background: "#F8F9FA" }}>
                              <Flex direction='column'>
                                <Text color="purple.500" fontSize='lg' fontWeight='bold' pb='.5rem'>
                                  ATR Details
                                </Text>
                                <hr></hr>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold" mt="15px">
                                  DNS:{" "}
                                  <Text as="span" color="gray.600">
                                    {row.atr_ip_address}
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  URL:{" "}
                                  <Text as="span" color="gray.600">
                                    <a href={row.atr_url} target="_blank">{row.atr_url}</a>
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  Admin Username:{" "}
                                  <Text as="span" color="gray.600">
                                    {row.atr_admin_username}
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  Admin Password:{" "}
                                  <Text as="span" color="gray.600">
                                    <InputGroup>
                                      <Input
                                        style={{ marginTop: "-6%", marginLeft: "33%" }}

                                        variant='unstyled'
                                        isReadOnly="true"
                                        pr='4.5rem'
                                        type={show1 ? 'text' : 'password'}
                                        defaultValue={row.atr_admin_password}
                                      />
                                      <InputRightElement width='2.5rem' style={{ marginTop: "-8%" }}>
                                        <Button h='1.75rem' size='sm' value='1' onClick={handleClick}>
                                          {show1 ? <ViewIcon style={{pointerEvents: 'none'}}/> : <ViewOffIcon style={{pointerEvents: 'none'}}/>}
                                        </Button>
                                      </InputRightElement>
                                    </InputGroup>
                                  </Text>
                                </Text>

                              </Flex>
                            </CardHeader>
                          </Card>
                          <Card maxH='100%' style={{ border: "2px solid #F8F9FA", background: "#F8F9FA" }} mb="15px">
                            <CardHeader p='5px 0px 5px 14px' style={{ background: "#F8F9FA" }}>
                              <Flex direction='column'>
                                <Text color="purple.500" fontSize='lg' fontWeight='bold' pb='.5rem'>
                                  Quasar++ Details
                                </Text>
                                <hr></hr>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold" mt="15px">
                                  DNS:{" "}
                                  <Text as="span" color="gray.600">
                                    {row.quasar_ip_address}
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  URL:{" "}
                                  <Text as="span" color="gray.600">
                                    <a href={row.quasar_url} target="_blank">{row.quasar_url}</a>
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  API User:{" "}
                                  <Text as="span" color="gray.600">
                                    {row.quasar_api_user}
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  API Password:{" "}
                                  <Text as="span" color="gray.600">
                                    <InputGroup>
                                      <Input
                                        style={{ marginTop: "-6%", marginLeft: "26%" }}

                                        variant='unstyled'
                                        isReadOnly="true"
                                        pr='4.5rem'
                                        type={show2 ? 'text' : 'password'}
                                        defaultValue={row.quasar_api_password}
                                      />
                                      <InputRightElement width='2.5rem' style={{ marginTop: "-8%" }}>
                                        <Button h='1.75rem' size='sm' value='2' onClick={handleClick}>
                                          {show2 ? <ViewIcon style={{pointerEvents: 'none'}}/> : <ViewOffIcon style={{pointerEvents: 'none'}}/>}
                                        </Button>
                                      </InputRightElement>
                                    </InputGroup>
                                  </Text>
                                </Text>

                              </Flex>
                            </CardHeader>
                          </Card>
                          <Card maxH='100%' style={{ border: "2px solid #F8F9FA", background: "#F8F9FA" }} mb="15px">
                            <CardHeader p='5px 0px 5px 14px' style={{ background: "#F8F9FA" }}>
                              <Flex direction='column'>
                                <Text color="purple.500" fontSize='lg' fontWeight='bold' pb='.5rem'>
                                  Chatbot Details
                                </Text>
                                <hr></hr>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold" mt="15px">
                                  Username:{" "}
                                  <Text as="span" color="gray.600">
                                    {row.teams_bot_username}
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  Password:{" "}
                                  <Text as="span" color="gray.600">
                                    <InputGroup>
                                      <Input
                                        style={{ marginTop: "-6%", marginLeft: "20%" }}
                                        variant='unstyled'
                                        isReadOnly="true"
                                        pr='4.5rem'
                                        type={show3 ? 'text' : 'password'}
                                        defaultValue={row.teams_bot_password}
                                      />
                                      <InputRightElement width='2.5rem' style={{ marginTop: "-8%" }}>
                                        <Button h='1.75rem' size='sm' value='3' onClick={handleClick}>
                                          {show3 ? <ViewIcon style={{pointerEvents: 'none'}}/> : <ViewOffIcon style={{pointerEvents: 'none'}}/>}
                                        </Button>
                                      </InputRightElement>
                                    </InputGroup>
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  App ID:{" "}
                                  <Text as="span" color="gray.600">
                                    {row.teams_bot_app_id}
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  App Secret:{" "}
                                  <Text as="span" color="gray.600">
                                    <InputGroup>
                                      <Input
                                        style={{ marginTop: "-6%", marginLeft: "23%" }}

                                        variant='unstyled'
                                        isReadOnly="true"
                                        pr='4.5rem'
                                        type={show4 ? 'text' : 'password'}
                                        defaultValue={row.teams_bot_app_secret}
                                      />
                                      <InputRightElement width='2.5rem' style={{ marginTop: "-7%" }}>
                                        <Button h='1.75rem' size='sm' value='4' onClick={handleClick}>
                                          {show4 ? <ViewIcon style={{pointerEvents: 'none'}}/> : <ViewOffIcon style={{pointerEvents: 'none'}}/>}
                                        </Button>
                                      </InputRightElement>
                                    </InputGroup>
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  Team Bot Name:{" "}
                                  <Text as="span" color="gray.600">
                                    {row.teams_bot_name}
                                  </Text>
                                </Text>

                              </Flex>
                            </CardHeader>
                          </Card>
                          <Card maxH='100%' style={{ border: "2px solid #F8F9FA", background: "#F8F9FA" }} mb="15px">
                            <CardHeader p='5px 0px 5px 14px' style={{ background: "#F8F9FA" }}>
                              <Flex direction='column'>
                                <Text color="purple.500" fontSize='lg' fontWeight='bold' pb='.5rem'>
                                  OpenAI Details
                                </Text>
                                <hr></hr>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold" mt="15px">
                                  Base URL:{" "}
                                  <Text as="span" color="gray.600">
                                    <a href={row.openai_base_url} target="_blank">{row.openai_base_url}</a>
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  Deployment Name:{" "}
                                  <Text as="span" color="gray.600">
                                    {row.openai_deployment_name}
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  Key:{" "}
                                  <Text as="span" color="gray.600">
                                    <InputGroup>
                                      <Input
                                        style={{ marginTop: "-3.5%", marginLeft: "5%" }}

                                        variant='unstyled'
                                        isReadOnly="true"
                                        pr='4.5rem'
                                        type={show5 ? 'text' : 'password'}
                                        defaultValue={row.openai_key}
                                      />
                                      <InputRightElement width='2.5rem' style={{ marginTop: "-4.5%" }}>
                                        <Button h='1.75rem' size='sm' value='5' onClick={handleClick}>
                                          {show5 ? <ViewIcon style={{pointerEvents: 'none'}}/> : <ViewOffIcon style={{pointerEvents: 'none'}}/>}
                                        </Button>
                                      </InputRightElement>
                                    </InputGroup>
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  Deployment Version:{" "}
                                  <Text as="span" color="gray.600">
                                    {row.openai_deployment_version}
                                  </Text>
                                </Text>
                              </Flex>
                            </CardHeader>
                          </Card>
                          <Card maxH='100%' style={{ border: "2px solid #F8F9FA", background: "#F8F9FA" }} mb="15px">
                            <CardHeader p='5px 0px 5px 14px' style={{ background: "#F8F9FA" }}>
                              <Flex direction='column'>
                                <Text color="purple.500" fontSize='lg' fontWeight='bold' pb='.5rem'>
                                  EventOps Details
                                </Text>
                                <hr></hr>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold" mt="15px">
                                  URL:{" "}
                                  <Text as="span" color="gray.600">
                                    <a href={row.eventops_url} target="_blank">{row.eventops_url}</a>
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  API GW URL:{" "}
                                  <Text as="span" color="gray.600">
                                    <a href={row.eventops_api_gateway_url} target="_blank">{row.eventops_api_gateway_url}</a>
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  API Key:{" "}
                                  <Text as="span" color="gray.600">
                                    <InputGroup>
                                      <Input
                                        style={{ marginTop: "-3.8%", marginLeft: "10%" }}

                                        variant='unstyled'
                                        isReadOnly="true"
                                        pr='4.5rem'
                                        type={show6 ? 'text' : 'password'}
                                        defaultValue={row.eventops_api_key}
                                      />
                                      <InputRightElement width='2.5rem' style={{ marginTop: "-4.5%" }}>
                                        <Button h='1.75rem' size='sm' value='6' onClick={handleClick}>
                                          {show6 ? <ViewIcon style={{pointerEvents: 'none'}}/> : <ViewOffIcon style={{pointerEvents: 'none'}}/>}
                                        </Button>
                                      </InputRightElement>
                                    </InputGroup>
                                  </Text>
                                </Text>
                              </Flex>
                            </CardHeader>
                          </Card>
                          <Card maxH='100%' style={{ border: "2px solid #F8F9FA", background: "#F8F9FA" }} mb="15px">
                            <CardHeader p='5px 0px 5px 14px' style={{ background: "#F8F9FA" }}>
                              <Flex direction='column'>
                                <Text color="purple.500" fontSize='lg' fontWeight='bold' pb='.5rem'>
                                  ServiceNow Details
                                </Text>
                                <hr></hr>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold" mt="15px">
                                  URL:{" "}
                                  <Text as="span" color="gray.600">
                                    <a href={row.servicenow_url} target="_blank">{row.servicenow_url}</a>
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  Admin User:{" "}
                                  <Text as="span" color="gray.600">
                                    {row.servicenow_admin_user}
                                  </Text>
                                </Text>
                                <Text color="gray.500" fontSize="16px" fontWeight="semibold">
                                  Admin Password:{" "}
                                  <Text as="span" color="gray.600">
                                    <InputGroup>
                                      <Input
                                        style={{ marginTop: "-7.8%", marginLeft: "44%" }}

                                        variant='unstyled'
                                        isReadOnly="true"
                                        pr='4.5rem'
                                        type={show7 ? 'text' : 'password'}
                                        defaultValue={row.servicenow_admin_password}
                                      />
                                      <InputRightElement width='2.5rem' style={{ marginTop: "-10.5%" }}>
                                        <Button h='1.75rem' size='sm' value='7' onClick={handleClick}>
                                          {show7 ? <ViewIcon style={{pointerEvents: 'none'}}/> : <ViewOffIcon style={{pointerEvents: 'none'}}/>}
                                        </Button>
                                      </InputRightElement>
                                    </InputGroup>
                                  </Text>
                                </Text>
                              </Flex>
                            </CardHeader>
                          </Card>

                        </AccordionPanel>
                      </AccordionItem>


                    </Accordion>
                  </div>
                );
              })
          )}
        </Grid>
        <Grid

          templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
          templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
          gap='24px'>

          <OrdersOverview
            title={"How to SSH to the Server"}
            amount={30}
            data={timelineData}
          />
        </Grid>
        <Grid

          templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
          templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
          gap='24px'>
          <Card p='16px' mt='24px' mb='24px'>
            <CardHeader>
              <Flex justify='space-between' align='center' minHeight='60px' w='100%'>
                <Text fontSize='lg' fontWeight='bold'>
                  How to Connect to VPN
                </Text>
              </Flex>
            </CardHeader>
            <Text fontSize='16px' mt={-3}>
              <p>Please click on the text below to view VPN connection details, then download it from the provided page.
              </p>
              <p><Button
                color="rgb(159, 122, 234)"
                target="_blank" fontSize='16px' fontWeight='bold'
                style={{ backgroundColor: "white", padding: "0px" }}
                onClick={() =>
                  fetchPresignedUrl("VPN_Connection_Steps_Mac.pdf")
                }
              >
                myWizard VPN - Mac
              </Button></p>
              <p><Button
                mt={-3}
                color="rgb(159, 122, 234)"
                target="_blank" fontSize='16px' fontWeight='bold'
                style={{ backgroundColor: "white", padding: "0px" }}
                onClick={() =>
                  fetchPresignedUrl("VPN_Connection_Steps_Windows.pdf")
                }
              >
                myWizard VPN - Windows
              </Button></p>

            </Text><br></br>

          </Card>
        </Grid>
      </Flex>
    </div>

  );
}

const MSTP = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken
});

export default connect(MSTP)(Bootcamps);