import React from "react";
import ReactDOM from "react-dom";
import { Offline, Online } from "react-detect-offline";

import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import rootReducer from "../src/redux";
import Routes from "routes";
import { JssProvider } from "react-jss";
import PrivateRoute from "privateRoute";
import { ToastContainer } from 'react-toastify';
import '../src/index.css';


import AuthLayout from "layouts/AuthPage.jsx";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import Dashboard from "views/Dashboard/Dashboard";
// import Dashboard from "layouts/Admin";

export const history = createBrowserHistory();
//initialise state & handle cookies
const initialState = {};



(async () => {
  //creating the redux store
  // Without middleware, Redux store only supports synchronous data flow.
  // This is what you get by default with createStore().
  const composedEnhancer = (history) =>
    composeWithDevTools(
      applyMiddleware(routerMiddleware(history), thunkMiddleware)
    );
  const store = createStore(
    rootReducer(history),
    initialState,
    composedEnhancer(history)
  );
  // Redux will be the source of truth for your data and
  // React Router will be the source of truth for your URL

  ReactDOM.render(
    <Provider store={store}>
      <ToastContainer  autoClose={7000}/>
        <JssProvider classNamePrefix={"mycloud"}>
          <div>
            <Online polling={{ enabled: false }}>
              <HashRouter>
                <Switch>
                  <Route path={`/auth`} component={AuthLayout} />
                  <PrivateRoute path={`/admin`} component={AdminLayout} />

                  <Route path={`/`} component={AuthLayout} />

                  {/* <Redirect from={`/admin/dashboard`} to="/admin/dashboard" /> */}
                </Switch>
              </HashRouter>
            </Online>
            <Offline polling={{ enabled: false }}>
              <Error />
            </Offline>
          </div>
        </JssProvider>
    </Provider>,
    document.getElementById("root")
  );
})();

