// Chakra imports
import { Flex, Text, useColorModeValue, Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton, Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  useDisclosure} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconBox from "components/Icons/IconBox";
import { Separator } from "components/Separator/Separator";
import React from "react";
import "../../Tables/components/Custom.css";


const PaymentStatistics = ({ icon, title, description, amount }) => {
  const iconTeal = useColorModeValue("purple.300", "purple.300");
  const textColor = useColorModeValue("purple.700", "white");
  const bgColor = useColorModeValue("white", "purple.700");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "purple.800"
  );
  const btnRef = React.useRef()

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Card p='16px' display='flex' align='center' justify='center'>
      <CardBody>
        <Flex direction='column' align='center' w='100%' py='14px'>
          {/* <IconBox as='box' h={"60px"} w={"60px"} bg={iconTeal}>
            {icon}
          </IconBox> */}
          <Flex
            direction='column'
            m='14px'
            justify='center'
            textAlign='center'
            align='center'
            w='100%'>
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
              {title}
            </Text>
            <Text
              mb='24px'
              fontSize='xs'
              color='purple.400'
              fontWeight='semibold'>
              {description}
            </Text>
            <Separator />
          </Flex>
          {/* <Link fontSize='xs' variant='no-hover' ref={btnRef} colorScheme='teal' onClick={onOpen}>
            <b>Start</b>
          </Link> */}
          <Button colorScheme="purple"
            variant="outline"
            type='submit'
            fontSize='16px'
            // w='30%'
            // h='45'
            mb='24px'
            justifyContent='center'
            _hover={{
              bg: "purple.200",
              color: 'white'
            }}
            _active={{
              bg: "purple.400",
              color: 'white'
            }}
            ref={btnRef}
            style={{ marginBottom: 0, width: "78px", height: "35px", borderRadius: "12px" }}
            onClick={onOpen}
          >
            Start
          </Button>
          <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              {/* <DrawerHeader>Create your account</DrawerHeader> */}

              <DrawerBody>

                <Flex
                  direction='column'
                  w='800px'
                  background='transparent'
                  borderRadius='15px'
                  p='40px'
                  mx={{ base: "100px" }}
                  bg={bgColor}
                  boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                  <Text
                    fontSize='xl'
                    color={textColor}
                    fontWeight='bold'
                    textAlign='center'
                    mb='22px' mt='40%'>
                    Sorry, this lab isn't available right now :(
                  </Text>

                  <Text
                    fontSize='s'
                    color={textColor}
                    fontWeight='bold'
                    textAlign='center'
                    mb='30%'>
                    We are working on it. Please get back.
                  </Text>
                  <HStack spacing='45px' justify='center' mb='52px'>
                  </HStack>

                </Flex>
              </DrawerBody>

              {/* <DrawerFooter>
                <Button variant='outline' mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='blue'>Save</Button>
              </DrawerFooter> */}
            </DrawerContent>
          </Drawer>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default PaymentStatistics;
