import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    HStack,
    FormControl,
    FormLabel,
    useColorModeValue, Badge, CircularProgress, Grid
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import { FaPencilAlt, FaTrashAlt, FaCheck } from "react-icons/fa";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import PendingApprovalsData from "./PendingApprovalsData";
import RequestedApprovalsData from "./RequestedApprovalsData";
import RequestedApprovalsList from "./RequestedApprovalsList";
import PendingApprovalsList from "./PendingApprovalsList";
import Pagination from "./Pagination";
import { useDisclosure } from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/react'


const Approvals = ({ title, requestedApprovals, pendingApprovals, allRequestedApprovals }) => {
    const textColor = useColorModeValue("purple.700", "white");
    const bgColor = useColorModeValue("#F8F9FA", "purple.800");
    const bgStatus = useColorModeValue("purple.400", "#1a202c");

    const idToken = localStorage.getItem("token");
    const eid = localStorage.getItem("eid");

    const baseURL = "https://api-cam-training.mywizard-aiops.com";
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [checkedItems, setCheckedItems] = useState({});
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [approveDeclineNotes, setApproveDeclineNotes] = useState("");
    const [approveAccountId, setApprovedAccountId] = useState("");
    const [declineAccountId, setDeclinedAccountId] = useState("");
    const [loading, setLoading] = useState(false);

    const [currentPage1, setCurrentPage1] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(3);

    const lastPostIndex1 = currentPage1 * postsPerPage;
    const firstPostIndex1 = lastPostIndex1 - postsPerPage;

    const lastPostIndex2 = currentPage2 * postsPerPage;
    const firstPostIndex2 = lastPostIndex2 - postsPerPage;

    const handleClose = () => {
        setOpen(false);
        setOpen2(false);
    };

    const declineAction = (element, accountId) => {
        setOpen(true);
        setCheckedItems(element);
        setDeclinedAccountId(accountId);
    };

    const approveAction = (element, accountId) => {
        setOpen2(true);
        setCheckedItems(element);
        setApprovedAccountId(accountId);
    };

    const acceptDeclineButton = () => {
        setLoading(true)
        let reqId = checkedItems.request_id;
        let accountId = declineAccountId;
        const url3 = `${baseURL}/ssh-management/requests/${reqId}/decline?notes=${approveDeclineNotes ? approveDeclineNotes : ""
            }&inititive=na&accountId=${accountId}`;

        Axios.get(url3, options).then((res) => {
            toast.success("Request to decline access is submitted successfully!")
            handleClose();
        })
            .catch((err) => {
                toast.error("ERROR! Please try again after sometime.");
                console.log("Async error", err);
                handleClose();
            }).finally(() => {
                setLoading(false)
                handleClose();
            });

        setCheckedItems({});
        // setOpen(false);
        reload(true);
        setApproveDeclineNotes("");
    };

    const handleApproveClick = () => {
        setLoading(true)
        let reqId = checkedItems.request_id;
        let accountId = approveAccountId;
        const url3 = `${baseURL}/ssh-management/requests/${reqId}/approve?notes=${approveDeclineNotes ? approveDeclineNotes : ""
            }&inititive=na&accountId=${accountId}`;

        Axios.get(url3, options).then((res) => {
            toast.success("Request to approve access is submitted successfully!")
            handleClose();
        })
            .catch((err) => {
                toast.error("ERROR! Please try again after sometime.");
                console.log("Async error", err);
                handleClose();
            }).finally(() => {
                setLoading(false)
                handleClose();
            });

        setCheckedItems({});
        // setOpen2(false);
        reload(true);
        setApproveDeclineNotes("");
    };

    const options = {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: idToken,
        },
    };

    const sortedRequestedData = allRequestedApprovals ? allRequestedApprovals.sort((a, b) => new Date(b.requested_on) - new Date(a.requested_on)) : undefined;
    const sortedPendingData = pendingApprovals ? pendingApprovals.sort((a, b) => new Date(b.requested_on) - new Date(a.requested_on)) : undefined;

    return (
        <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
            <Grid item xs={6}>
                <Grid templateColumns={{ sm: "1fr", lg: "2.1fr 1.2fr" }} templateRows='1fr'>
                    <Card p='16px' mt='-45px' style={{ width: "171%" }}>
                        <div>
                            <Text fontSize='lg' color={textColor} fontWeight='bold' style={{ textAlign: "left" }}>
                                Pending Approvals
                            </Text>
                            <PendingApprovalsList approvalData={sortedPendingData ? sortedPendingData.slice(firstPostIndex1, lastPostIndex1) : undefined} />
                            {sortedPendingData ?
                                sortedPendingData.length > 0 ? <Pagination
                                    totalPosts={sortedPendingData ? sortedPendingData.length : 1}
                                    postsPerPage={postsPerPage}
                                    setCurrentPage={setCurrentPage1}
                                    currentPage={currentPage1}
                                /> : " "
                                : " "}
                        </div>

                    </Card>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid templateColumns={{ sm: "1fr", lg: "2.1fr 1.2fr" }} templateRows='1fr'>
                    <Card p='16px' mt='24px' style={{ width: "171%" }}>
                        <div>
                            <Text fontSize='lg' color={textColor} fontWeight='bold' style={{ textAlign: "left" }}>
                                Requested Approvals
                            </Text>
                            <RequestedApprovalsList approvalData={sortedRequestedData ? sortedRequestedData.slice(firstPostIndex2, lastPostIndex2) : undefined} />

                            {sortedRequestedData ?
                                sortedRequestedData.length > 0 ? <Pagination
                                    totalPosts={sortedRequestedData ? sortedRequestedData.length : 1}
                                    postsPerPage={postsPerPage}
                                    setCurrentPage={setCurrentPage2}
                                    currentPage={currentPage2}
                                /> : " "
                                : " "}
                        </div>
                    </Card>
                </Grid>
            </Grid>
            <Modal isOpen={open2} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Request Submit Successful</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody>

                        <Flex
                            direction='column'
                            w='800px'
                            background='transparent'
                            borderRadius='15px'
                            p='40px'
                            mx={{ base: "100px" }}
                            bg={bgColor}
                            boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                            <Text
                                fontSize='xl'
                                color={textColor}
                                fontWeight='bold'
                                textAlign='center'
                                mb='22px'>
                                Approve Access Request
                            </Text>

                            <HStack spacing='15px' justify='center' mb='22px'>
                            </HStack>

                            <FormControl>
                                <FormLabel ms='4px' fontSize='md' fontWeight='normal'>
                                    Are you sure you want to approve?
                                </FormLabel>
                                <Textarea
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    type='text'
                                    placeholder='Notes'
                                    mb='24px'
                                    size='lg'
                                    onChange={(e) =>
                                        setApproveDeclineNotes(e.target.value.trim())
                                    }
                                />
                                <Button colorScheme="purple"
                                    variant="outline"
                                    type='submit'
                                    fontSize='16px'
                                    float='left'
                                    w='30%'
                                    h='45'
                                    mb='24px'
                                    justifyContent='center'
                                    _hover={{
                                        bg: "purple.200",
                                        color: 'white'
                                    }}
                                    _active={{
                                        bg: "purple.400",
                                        color: 'white'
                                    }}
                                    onClick={handleApproveClick}
                                    isLoading={loading}
                                // onClick={handleDelete}
                                // style={{ width: "auto" }}
                                >
                                    SUBMIT
                                </Button>
                                {/* <Button colorScheme="purple"
                                    variant="outline"
                                    type='submit'
                                    fontSize='16px'
                                    w='30%'
                                    h='45'
                                    mb='24px'
                                    justifyContent='center'
                                    _hover={{
                                        bg: "purple.200",
                                        color: 'white'
                                    }}
                                    _active={{
                                        bg: "purple.400",
                                        color: 'white'
                                    }}
                                    onClick={onClose}
                                >
                                    CANCEL
                                </Button> */}
                            </FormControl>


                        </Flex>
                        {/* <Text>Your request will take up 3 minutes.</Text> */}
                        {/* <div style={{ width: "100%", padding: "0 0 15px 0" }}> */}
                        {/* <Textarea
                            required
                            onChange={(e) =>
                                setApproveDeclineNotes(e.target.value.trim())
                            }
                            placeholder='Notes'
                            size='sm'
                            isInvalid={approveDeclineNotes.length > 50 ? true : false}

                        /> */}
                        {/* </div> */}
                    </ModalBody>

                    {/* <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant='ghost'
                            isDisabled={
                                approveDeclineNotes.length > 0 &&
                                    approveDeclineNotes.length < 51
                                    ? false
                                    : true
                            }
                            onClick={acceptDeclineButton}
                        >OK
                        </Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
            <Modal isOpen={open} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Confirm</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex
                            direction='column'
                            w='800px'
                            background='transparent'
                            borderRadius='15px'
                            p='40px'
                            mx={{ base: "100px" }}
                            bg={bgColor}
                            boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                            <Text
                                fontSize='xl'
                                color={textColor}
                                fontWeight='bold'
                                textAlign='center'
                                mb='22px'>
                                Decline Access Request
                            </Text>
                            {/* <Text
                                fontSize='s'
                                color={textColor}
                                fontWeight='bold'
                                textAlign='center'
                                mb='22px'>
                                Are you sure you want to approve?
                            </Text> */}
                            <HStack spacing='15px' justify='center' mb='22px'>
                            </HStack>

                            <FormControl>
                                <FormLabel ms='4px' fontSize='md' fontWeight='normal'>
                                    Are you sure you want to decline?
                                </FormLabel>
                                <Textarea
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    type='text'
                                    placeholder='Notes*'
                                    mb='24px'
                                    size='lg'
                                // onChange={event => setPubKey(event.currentTarget.value)}
                                />
                                <Button colorScheme="purple"
                                    variant="outline"
                                    type='submit'
                                    fontSize='16px'
                                    float='left'
                                    w='30%'
                                    h='45'
                                    mb='24px'
                                    justifyContent='center'
                                    _hover={{
                                        bg: "purple.200",
                                        color: 'white'
                                    }}
                                    _active={{
                                        bg: "purple.400",
                                        color: 'white'
                                    }}
                                    onClick={acceptDeclineButton}
                                    isLoading={loading}
                                // onClick={handleDelete}
                                // style={{ width: "auto" }}
                                >
                                    SUBMIT
                                </Button>
                                {/* <Button colorScheme="purple"
                                    variant="outline"
                                    type='submit'
                                    fontSize='16px'
                                    w='30%'
                                    h='45'
                                    mb='24px'
                                    justifyContent='center'
                                    _hover={{
                                        bg: "purple.200",
                                        color: 'white'
                                    }}
                                    _active={{
                                        bg: "purple.400",
                                        color: 'white'
                                    }}
                                    onClick={onClose}
                                >
                                    CANCEL
                                </Button> */}
                            </FormControl>


                        </Flex>


                        {/* <Text>Are you sure you want to decline?</Text> */}
                        {/* <div style={{ width: "100%", padding: "0 0 15px 0" }}> */}
                        {/* <Textarea
                            required
                            onChange={(e) =>
                                setApproveDeclineNotes(e.target.value.trim())
                            }
                            placeholder='Notes'
                            size='sm'
                            isInvalid={approveDeclineNotes.length > 50 ? true : false}

                        /> */}
                        {/* </div> */}
                    </ModalBody>

                    {/* <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant='ghost'
                            isDisabled={
                                approveDeclineNotes.length > 0 &&
                                    approveDeclineNotes.length < 51
                                    ? false
                                    : true
                            }
                            onClick={acceptDeclineButton}
                        >OK
                        </Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </Flex>
    );
}

export default Approvals;
