import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom"
import { getUserDetails, saveTokens } from "./redux/auth/authActions";
import { CircularProgress } from "@chakra-ui/react";

const PrivateRoute = ({
  component: Component,
  getUserDetails,
  saveTokens,
//   checkIsRegistration,
  exp,
  email,
  isAuthenticated,
  loadingAuthentication,
  ...rest
}) => {

  const auth = localStorage.getItem("token");
  useEffect(() => {
    exp && localStorage.setItem("exp", exp);
  }, [exp]);
  useEffect(() => {
    // if (email) {
    //   checkIsRegistration(email, auth);
    // }
    let tokenInJson = localStorage.getItem("tokenObj");
    saveTokens(JSON.parse(tokenInJson));
    getUserDetails(localStorage.getItem("token"));
  }, [email, auth]);

  return (
    <Route
      {...rest}
      render={props =>
        loadingAuthentication ?
          <div style={{
            width: '100%',
            height: '100%',
            display: 'flex'
          }}>
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <CircularProgress isIndeterminate color='#7500c0' />
            </div>
          </div> :
          isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    exp: state.auth.userDetails.exp,
    email: state.auth.userDetails.email,
    loadingAuthentication: state.auth.loadingAuthentication,
    isAuthenticated: state.auth.isAuthenticated
  };
};
const mapDispatchToProps = dispatch => ({
  getUserDetails: idToken => dispatch(getUserDetails(idToken)),
  saveTokens: tokenObj => dispatch(saveTokens(tokenObj)),
  checkIsRegistration: (email, idToken) => dispatch(checkIsRegistration(email, idToken))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
