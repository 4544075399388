import React, { useState } from "react";
import PendingApprovalsData from "./PendingApprovalsData";
import {
    CircularProgress,
    Text,
    useColorModeValue,
    Button,
    Flex,
    HStack,
    FormControl,
    FormLabel,
    Grid,
} from "@chakra-ui/react";
import { useDisclosure } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/react'
import Card from "components/Card/Card.js";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PendingApprovalsDataList = ({ approvalData }) => {
    const textColor = useColorModeValue("purple.700", "white");
    const bgColor = useColorModeValue("#F8F9FA", "purple.800");
    const bgStatus = useColorModeValue("purple.400", "#1a202c");

    const idToken = localStorage.getItem("token");
    const eid = localStorage.getItem("eid");

    const baseURL = "https://api-cam-training.mywizard-aiops.com";
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [checkedItems, setCheckedItems] = useState({});
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [approveDeclineNotes, setApproveDeclineNotes] = useState("");
    const [approveAccountId, setApprovedAccountId] = useState("");
    const [declineAccountId, setDeclinedAccountId] = useState("");
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setOpen2(false);
    };

    const declineAction = (element, accountId) => {
        setOpen(true);
        setCheckedItems(element);
        setDeclinedAccountId(accountId);
    };

    const approveAction = (element, accountId) => {
        setOpen2(true);
        setCheckedItems(element);
        setApprovedAccountId(accountId);
    };

    const acceptDeclineButton = () => {
        setLoading(true)
        let reqId = checkedItems.request_id;
        let accountId = declineAccountId;
        const url3 = `${baseURL}/ssh-management/requests/${reqId}/decline?notes=${approveDeclineNotes ? approveDeclineNotes : ""
            }&inititive=na&accountId=${accountId}`;

        Axios.get(url3, options).then((res) => {
            toast.success("Request to decline access is submitted successfully!")
            handleClose();
        })
            .catch((err) => {
                toast.error("ERROR! Please try again after sometime.");
                console.log("Async error", err);
                handleClose();
            }).finally(() => {
                setLoading(false)
                handleClose();
            });

        setCheckedItems({});
        // setOpen(false);
        reload(true);
        setApproveDeclineNotes("");
    };

    const handleApproveClick = () => {
        setLoading(true)
        let reqId = checkedItems.request_id;
        let accountId = approveAccountId;
        const url3 = `${baseURL}/ssh-management/requests/${reqId}/approve?notes=${approveDeclineNotes ? approveDeclineNotes : ""
            }&inititive=na&accountId=${accountId}`;

        Axios.get(url3, options).then((res) => {
            toast.success("Request to approve access is submitted successfully!")
            handleClose();
        })
            .catch((err) => {
                toast.error("ERROR! Please try again after sometime.");
                console.log("Async error", err);
                handleClose();
            }).finally(() => {
                setLoading(false)
                handleClose();
            });

        setCheckedItems({});
        // setOpen2(false);
        reload(true);
        setApproveDeclineNotes("");
    };

    const options = {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: idToken,
        },
    };
    return (
        <div className="approval_data_list">
            {
                approvalData ?
                    (approvalData.length !== 0 ?
                        approvalData.map((row) => {
                            return (
                                <PendingApprovalsData
                                    request_id={row.request_id}
                                    private_dns={row.private_dns}
                                    requestor={row.requestor}
                                    requested_on={row.requested_on}
                                    approvalButton={
                                        <Button colorScheme="purple"
                                            variant="outline"
                                            type='submit'
                                            fontSize='16px'
                                            w='30%'
                                            h='45'
                                            mb='24px'
                                            justifyContent='center'
                                            _hover={{
                                                bg: "purple.200",
                                                color: 'white'
                                            }}
                                            _active={{
                                                bg: "purple.400",
                                                color: 'white'
                                            }}
                                            style={{ marginBottom: 0, width: "98px", height: "35px", borderRadius: "12px" }}
                                            value={row.account_id}
                                            onClick={() => approveAction(row, row.account_id)}

                                        >
                                            <Flex cursor="pointer" align="center" p="12px">
                                                {/* <Icon as={FaPencilAlt} me="4px" /> */}
                                                {/* <Icon as={FaCheck} style={{color: "#63bb7d",}} /> */}
                                                <Text fontSize="16px" fontWeight="semibold">
                                                    APPROVE
                                                </Text>
                                            </Flex>
                                        </Button>
                                    }
                                    declineButton={
                                        <Button colorScheme="purple"
                                            variant="outline"
                                            type='submit'
                                            fontSize='16px'
                                            w='30%'
                                            h='45'
                                            mb='24px'
                                            justifyContent='center'
                                            _hover={{
                                                bg: "purple.200",
                                                color: 'white'
                                            }}
                                            _active={{
                                                bg: "purple.400",
                                                color: 'white'
                                            }}
                                            style={{ marginBottom: 0, width: "98px", height: "35px", borderRadius: "12px" }}
                                            value={row.account_id}
                                            onClick={() => declineAction(row, row.account_id)}

                                        >
                                            <Flex cursor="pointer" align="center" p="12px">
                                                {/* <Icon as={FaTrashAlt} me="4px" /> */}
                                                {/* <Icon as={FaXmark} style={{color: "#d60a0a",}} /> */}
                                                <Text fontSize="16px" fontWeight="semibold">
                                                    DECLINE
                                                </Text>
                                            </Flex>
                                        </Button>
                                    }
                                />
                            );
                        }) : <Text fontSize='sm' mt='2'>No Pending approvals</Text>
                    ) :
                    <div style={{
                        width: '80%',
                        height: '80%',
                        display: 'flex'
                    }}>
                        <div style={{
                            width: '80%',
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'left',
                            marginTop: '2%'
                        }}>
                            <CircularProgress isIndeterminate color='#7500c0' size="2.5rem" />
                        </div>
                    </div>
            }
            <Modal isOpen={open2} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Request Submit Successful</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody>

                        <Flex
                            direction='column'
                            w='800px'
                            background='transparent'
                            borderRadius='15px'
                            p='40px'
                            mx={{ base: "100px" }}
                            bg={bgColor}
                            boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                            <Text
                                fontSize='xl'
                                color={textColor}
                                fontWeight='bold'
                                textAlign='center'
                                mb='22px'>
                                Approve Access Request
                            </Text>

                            <HStack spacing='15px' justify='center' mb='22px'>
                            </HStack>

                            <FormControl>
                                <FormLabel ms='4px' fontSize='md' fontWeight='normal'>
                                    Are you sure you want to approve?
                                </FormLabel>
                                <Textarea
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    type='text'
                                    placeholder='Notes'
                                    mb='24px'
                                    size='lg'
                                    onChange={(e) =>
                                        setApproveDeclineNotes(e.target.value.trim())
                                    }
                                />
                                <Button colorScheme="purple"
                                    variant="outline"
                                    type='submit'
                                    fontSize='16px'
                                    float='left'
                                    w='30%'
                                    h='45'
                                    mb='24px'
                                    justifyContent='center'
                                    _hover={{
                                        bg: "purple.200",
                                        color: 'white'
                                    }}
                                    _active={{
                                        bg: "purple.400",
                                        color: 'white'
                                    }}
                                    onClick={handleApproveClick}
                                    isLoading={loading}
                                // onClick={handleDelete}
                                // style={{ width: "auto" }}
                                >
                                    SUBMIT
                                </Button>
                                {/* <Button colorScheme="purple"
                                    variant="outline"
                                    type='submit'
                                    fontSize='16px'
                                    w='30%'
                                    h='45'
                                    mb='24px'
                                    justifyContent='center'
                                    _hover={{
                                        bg: "purple.200",
                                        color: 'white'
                                    }}
                                    _active={{
                                        bg: "purple.400",
                                        color: 'white'
                                    }}
                                    onClick={onClose}
                                >
                                    CANCEL
                                </Button> */}
                            </FormControl>


                        </Flex>
                        {/* <Text>Your request will take up 3 minutes.</Text> */}
                        {/* <div style={{ width: "100%", padding: "0 0 15px 0" }}> */}
                        {/* <Textarea
                            required
                            onChange={(e) =>
                                setApproveDeclineNotes(e.target.value.trim())
                            }
                            placeholder='Notes'
                            size='sm'
                            isInvalid={approveDeclineNotes.length > 50 ? true : false}

                        /> */}
                        {/* </div> */}
                    </ModalBody>

                    {/* <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant='ghost'
                            isDisabled={
                                approveDeclineNotes.length > 0 &&
                                    approveDeclineNotes.length < 51
                                    ? false
                                    : true
                            }
                            onClick={acceptDeclineButton}
                        >OK
                        </Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
            <Modal isOpen={open} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Confirm</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex
                            direction='column'
                            w='800px'
                            background='transparent'
                            borderRadius='15px'
                            p='40px'
                            mx={{ base: "100px" }}
                            bg={bgColor}
                            boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                            <Text
                                fontSize='xl'
                                color={textColor}
                                fontWeight='bold'
                                textAlign='center'
                                mb='22px'>
                                Decline Access Request
                            </Text>
                            {/* <Text
                                fontSize='s'
                                color={textColor}
                                fontWeight='bold'
                                textAlign='center'
                                mb='22px'>
                                Are you sure you want to approve?
                            </Text> */}
                            <HStack spacing='15px' justify='center' mb='22px'>
                            </HStack>

                            <FormControl>
                                <FormLabel ms='4px' fontSize='md' fontWeight='normal'>
                                    Are you sure you want to decline?
                                </FormLabel>
                                <Textarea
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    type='text'
                                    placeholder='Notes*'
                                    mb='24px'
                                    size='lg'
                                // onChange={event => setPubKey(event.currentTarget.value)}
                                />
                                <Button colorScheme="purple"
                                    variant="outline"
                                    type='submit'
                                    fontSize='16px'
                                    float='left'
                                    w='30%'
                                    h='45'
                                    mb='24px'
                                    justifyContent='center'
                                    _hover={{
                                        bg: "purple.200",
                                        color: 'white'
                                    }}
                                    _active={{
                                        bg: "purple.400",
                                        color: 'white'
                                    }}
                                    onClick={acceptDeclineButton}
                                    isLoading={loading}
                                // onClick={handleDelete}
                                // style={{ width: "auto" }}
                                >
                                    SUBMIT
                                </Button>
                                {/* <Button colorScheme="purple"
                                    variant="outline"
                                    type='submit'
                                    fontSize='16px'
                                    w='30%'
                                    h='45'
                                    mb='24px'
                                    justifyContent='center'
                                    _hover={{
                                        bg: "purple.200",
                                        color: 'white'
                                    }}
                                    _active={{
                                        bg: "purple.400",
                                        color: 'white'
                                    }}
                                    onClick={onClose}
                                >
                                    CANCEL
                                </Button> */}
                            </FormControl>


                        </Flex>


                        {/* <Text>Are you sure you want to decline?</Text> */}
                        {/* <div style={{ width: "100%", padding: "0 0 15px 0" }}> */}
                        {/* <Textarea
                            required
                            onChange={(e) =>
                                setApproveDeclineNotes(e.target.value.trim())
                            }
                            placeholder='Notes'
                            size='sm'
                            isInvalid={approveDeclineNotes.length > 50 ? true : false}

                        /> */}
                        {/* </div> */}
                    </ModalBody>

                    {/* <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant='ghost'
                            isDisabled={
                                approveDeclineNotes.length > 0 &&
                                    approveDeclineNotes.length < 51
                                    ? false
                                    : true
                            }
                            onClick={acceptDeclineButton}
                        >OK
                        </Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default PendingApprovalsDataList;
